import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AuthCard } from "../../../components";
import {
  EmailTextField,
  PasswordTextField,
  LinkButton,
  ActionButton,
} from "../../../components";
import { api, urls } from "../../../services";
import { useAuth } from "../../../contexts";
import validator from "validator";
import { useToast } from "../../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiContainer-root": {
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  forgotContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: theme.spacing(1),
  },
}));

export function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    errors: { email: false, password: false },
  });
  const { showToast } = useToast();

  const handleForgotClick = () => {
    navigate("/forgot", { replace: true });
  };

  const handleLoginClick = () => {
    let invalid = false;
    if (!formData.email || !validator.isEmail(formData.email)) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.email = true;
        return curr;
      });
      invalid = true;
    }
    if (!formData.password) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.password = true;
        return curr;
      });
      invalid = true;
    }

    if (!invalid) {
      const params = { ...formData };
      delete params.errors;
      api
        .post(urls.login, params)
        .then((res) => {
          showToast(res.data.message, "success");
          login();
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    }
  };

  const updateFormData = (key, value) => {
    setFormData((prev) => {
      const curr = { ...prev };
      curr[key] = value;
      curr.errors[key] = false;
      return curr;
    });
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <AuthCard>
        <Grid container spacing={3} direction="column">
          <Grid item xs>
            <EmailTextField
              label="Email ID"
              value={formData.email}
              error={formData.errors.email}
              helperText={formData.errors.email ? "Enter valid email" : null}
              onChange={(e) => updateFormData("email", e.target.value)}
            />
          </Grid>
          <Grid item xs>
            <PasswordTextField
              label="Password"
              value={formData.password}
              error={formData.errors.password}
              helperText={formData.errors.email ? "Enter password" : null}
              onChange={(e) => updateFormData("password", e.target.value)}
            />
          </Grid>
          <Grid item xs>
            <Grid container spacing={1} direction="column">
              <Grid item className={classes.forgotContainer}>
                <LinkButton onClick={handleForgotClick}>
                  Forgot Password?
                </LinkButton>
              </Grid>
              <Grid item>
                <ActionButton onClick={handleLoginClick}>Login</ActionButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AuthCard>
    </Container>
  );
}
