import { Fragment } from "react";
import { Paper, Box, Typography, Divider, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { logo, tick } from "../../assets";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      minWidth: "90%",
    },
    minWidth: 420,
  },
  titleBar: {
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  logoImage: {
    width: 220,
    height: 70,
  },
  titleText: {
    display: "flex",
    justifyContent: "center",
    "&.MuiTypography-root": {
      fontSize: 24,
      fontWeight: 600,
      marginBottom: theme.spacing(4),
    },
  },
  divider: {
    "&.MuiDivider-root": {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(-4),
      marginRight: theme.spacing(-4),
    },
  },
  messageText: {
    display: "flex",
    justifyContent: "center",
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 400,
    },
  },
  tickImage: {
    width: 60,
    height: 60,
  },
}));

export function AuthCard(props) {
  const classes = useStyles();
  const { title, success } = props;

  return (
    <Paper className={classes.root}>
      {success && (
        <Fragment>
          <Stack spacing={4} alignItems="center">
            <img className={classes.tickImage} alt="tick" src={tick} />
            <Typography className={classes.messageText}>
              Your email has been verified
            </Typography>
          </Stack>
          <Divider className={classes.divider} />
        </Fragment>
      )}
      <Box className={classes.titleBar}>
        <img className={classes.logoImage} alt="logo" src={logo} />
      </Box>
      <Typography className={classes.titleText}>{title}</Typography>
      {props.children}
    </Paper>
  );
}

AuthCard.propTypes = {
  title: PropTypes.string,
  success: PropTypes.bool,
};
