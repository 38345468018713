import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Stack,
  Box,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  DeleteOutline,
  RadioButtonUnchecked,
  CheckCircle,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import {
  NormalTextField,
  HeaderButton,
  ContainerCard,
  BlockButton,
  ComponentCard,
} from "../../../components";
import { useState, useEffect } from "react";
import { magazine1 } from "../../../assets";
import { useToast, useAuth } from "../../../contexts";
import { api, urls } from "../../../services";
import { cloneDeep } from "lodash";

const useStyles = makeStyles((theme) => ({
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
    // display: "flex",
    // justifyContent: "center",
    // paddingBottom: theme.spacing(3),
  },
  buttons: {
    "&.MuiIconButton-root": {
      border: 1,
      borderStyle: "solid",
      borderColor: theme.palette.common.border,
      borderRadius: 4,
      height: 35,
      width: 35,
      color: "black",
    },
  },
  checkBox: {
    "&.MuiCheckbox-root": {
      color: "black",
      "&.Mui-checked": {
        color: theme.palette.common.greenTxt,
      },
      "&.Mui-disabled": {
        color: theme.palette.common.border,
      },
    },
  },
}));

export const CreateQuiz = (props) => {
  const classes = useStyles();
  const { open, onClose, onSubmit, isEdit, selQuiz, selMag } = props;
  const [quizData, setQuizData] = useState({
    quiz_name: "",
    questions: [
      {
        question: "",
        options: [{ answer: "" }, { answer: "" }],
        correct_answer: 0,
      },
    ],
    errors: {
      quiz_name: false,
      questions: [],
    },
  });

  const { showToast } = useToast();
  const { logout } = useAuth();
  const quesBtnRef = useRef(null);
  const ansBtnRef = useRef(null);

  useEffect(() => {
    console.log("quizData: ", quizData);
  }, [quizData]);

  useEffect(() => {
    if (isEdit) {
      api
        .get(urls.viewQuiz + selQuiz.id)
        .then((res) => {
          let data = res.data.data;
          delete data.id;
          data.questions.map((q) => {
            delete q.id;
            let index = q.options.findIndex(
              (x) => x.answer === q.correct_answer
            );
            q.correct_answer = index;
          });
          setQuizData((prev) => ({ ...prev, ...data }));
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            logout();
          } else {
            showToast(err.response?.data?.message, "error");
          }
        });
    }
  }, [selQuiz]);

  const handleCreate = () => {
    let invalid = false;
    setQuizData((prev) => {
      const curr = { ...prev };
      curr.errors = {
        quiz_name: false,
        questions: [],
      };
      return curr;
    });
    if (!quizData.quiz_name.trim()) {
      setQuizData((prev) => {
        const curr = { ...prev };
        curr.errors.quiz_name = true;
        return curr;
      });
      invalid = true;
    }
    quizData.questions.map((q, i) => {
      if (!q.question.trim()) {
        setQuizData((prev) => {
          const curr = { ...prev };
          curr.errors.questions.push(i);
          return curr;
        });
        invalid = true;
      } else {
        q.options.map((o, j) => {
          if (!o.answer.trim()) {
            setQuizData((prev) => {
              const curr = { ...prev };
              curr.errors.questions.push(i);
              return curr;
            });
            invalid = true;
          }
        });
      }
    });
    if (invalid) {
      showToast("Please fill all required fields", "error");
    } else {
      let params = cloneDeep(quizData);
      delete params.errors;
      params.questions.map((q, i) => {
        q.correct_answer = q.options[q.correct_answer].answer;
        q.order = i + 1;
      });

      if (isEdit) {
        api
          .put(urls.editQuiz + selQuiz.id, params)
          .then((res) => {
            showToast(res.data.message, "success");
            onSubmit();
          })
          .catch((err) => {
            if (err.response?.status === 401) {
              logout();
            } else {
              showToast(err.response?.data?.message, "error");
            }
          });
      } else {
        api
          .post(urls.createQuiz + selMag.id, params)
          .then((res) => {
            showToast(res.data.message, "success");
            onSubmit();
          })
          .catch((err) => {
            if (err.response?.status === 401) {
              logout();
            } else {
              showToast(err.response?.data?.message, "error");
            }
          });
      }
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle
        // className={classes.titleText}
        sx={{
          bgcolor: "common.greyBg",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography className={classes.titleText}>
            {isEdit ? "Edit Quiz" : "Create New Quiz"}
          </Typography>
          <Stack direction="row" spacing={2} py={1} sx={{ width: 300 }}>
            <HeaderButton type="secondary" onClick={onClose}>
              Cancel
            </HeaderButton>
            <HeaderButton type="primary" onClick={handleCreate}>
              {isEdit ? "Update" : "Create"}
            </HeaderButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          bgcolor: "common.greyBg",
        }}
      >
        <Stack direction={"row"} spacing={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <ContainerCard>
              <Stack spacing={2} alignItems={"center"}>
                <img
                  style={{ width: 200 }}
                  src={isEdit ? selMag?.image : selMag?.cover_image}
                />
                <Stack alignItems={"center"}>
                  <Typography
                    sx={{ fontSize: 15, fontWeight: 500, color: "theme.main" }}
                  >
                    {`പുസ്തകം ${selMag?.book_number} - ${selMag?.year_number}`}
                  </Typography>
                  <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
                    {selMag?.issue_name}
                  </Typography>
                </Stack>
                <NormalTextField
                  label="Quiz No"
                  onChange={(e) => {
                    setQuizData({
                      ...quizData,
                      quiz_name: e.target.value,
                      errors: { ...quizData.errors, quiz_name: false },
                    });
                  }}
                  value={quizData.quiz_name}
                  error={quizData.errors.quiz_name}
                  helperText={quizData.errors.quiz_name && "Enter quiz no."}
                />
              </Stack>
            </ContainerCard>
          </Box>
          <Stack spacing={2} width={"100%"}>
            {quizData.questions.map((question, index1) => (
              <ComponentCard
                key={index1}
                title={`Question ${index1 + 1}`}
                disableUp={index1 === 0}
                disableDown={index1 === quizData.questions.length - 1}
                onMoveUp={() => {
                  setQuizData((prevState) => {
                    const questions = [...prevState.questions];
                    if (index1 > 0) {
                      const question = questions.splice(index1, 1)[0];
                      questions.splice(index1 - 1, 0, question);
                    }
                    return { ...prevState, questions };
                  });
                }}
                onMoveDown={() => {
                  setQuizData((prevState) => {
                    const questions = [...prevState.questions];
                    if (index1 < questions.length - 1) {
                      const question = questions.splice(index1, 1)[0];
                      questions.splice(index1 + 1, 0, question);
                    }
                    return { ...prevState, questions };
                  });
                }}
                onDuplicate={() => {
                  setQuizData({
                    ...quizData,
                    questions: [
                      ...quizData.questions.slice(0, index1),
                      { ...question },
                      ...quizData.questions.slice(index1),
                    ],
                  });
                }}
                onDelete={() => {
                  setQuizData({
                    ...quizData,
                    questions: [
                      ...quizData.questions.slice(0, index1),
                      ...quizData.questions.slice(index1 + 1),
                    ],
                  });
                }}
                hideIsFree
                disableDelete={quizData.questions.length === 1}
                disableAll={isEdit}
              >
                <Stack
                  spacing={2}
                  sx={
                    quizData.errors.questions.find((x) => x === index1) ===
                    index1
                      ? {
                          border: 1,
                          borderColor: "common.redTxt",
                          padding: 2,
                          borderRadius: 2,
                        }
                      : {}
                  }
                >
                  <NormalTextField
                    label={`Question ${index1 + 1}`}
                    multiline={true}
                    onChange={(e) => {
                      setQuizData({
                        ...quizData,
                        questions: [
                          ...quizData.questions.slice(0, index1),
                          {
                            ...question,
                            question: e.target.value,
                          },
                          ...quizData.questions.slice(index1 + 1),
                        ],
                        errors: {
                          ...quizData.errors,
                          questions: quizData.errors.questions.filter(
                            (x) => x !== index1
                          ),
                        },
                      });
                    }}
                    value={question.question}
                  />
                  <Stack spacing={2}>
                    {question.options.map((answer, index2) => (
                      <Stack
                        key={index2}
                        direction={"row"}
                        spacing={2}
                        alignItems="center"
                      >
                        <NormalTextField
                          key={index2}
                          label={`Answer ${index2 + 1}`}
                          onChange={(e) => {
                            setQuizData({
                              ...quizData,
                              questions: [
                                ...quizData.questions.slice(0, index1),
                                {
                                  ...question,
                                  options: [
                                    ...question.options.slice(0, index2),
                                    { answer: e.target.value },
                                    ...question.options.slice(index2 + 1),
                                  ],
                                },
                                ...quizData.questions.slice(index1 + 1),
                              ],
                              errors: {
                                ...quizData.errors,
                                questions: quizData.errors.questions.filter(
                                  (x) => x !== index1
                                ),
                              },
                            });
                          }}
                          value={answer?.answer}
                        />
                        <FormControlLabel
                          disabled={isEdit}
                          control={
                            <Checkbox
                              className={classes.checkBox}
                              icon={<RadioButtonUnchecked />}
                              checkedIcon={<CheckCircle />}
                              checked={question.correct_answer === index2}
                              onChange={(e) => {
                                setQuizData({
                                  ...quizData,
                                  questions: [
                                    ...quizData.questions.slice(0, index1),
                                    {
                                      ...question,
                                      correct_answer: e.target.checked
                                        ? index2
                                        : -1,
                                    },
                                    ...quizData.questions.slice(index1 + 1),
                                  ],
                                });
                              }}
                            />
                          }
                          label="Correct"
                        />
                        <IconButton
                          size="small"
                          className={classes.buttons}
                          disabled={question.options.length <= 2 || isEdit}
                          onClick={() => {
                            setQuizData({
                              ...quizData,
                              questions: [
                                ...quizData.questions.slice(0, index1),
                                {
                                  ...question,
                                  options: [
                                    ...question.options.slice(0, index2),
                                    ...question.options.slice(index2 + 1),
                                  ],
                                  correct_answer: 0,
                                },
                                ...quizData.questions.slice(index1 + 1),
                              ],
                              errors: {
                                ...quizData.errors,
                                questions: quizData.errors.questions.filter(
                                  (x) => x !== index1
                                ),
                              },
                            });
                          }}
                        >
                          <DeleteOutline sx={{ height: 18, width: 18 }} />
                        </IconButton>
                      </Stack>
                    ))}
                  </Stack>
                  <Stack alignItems={"flex-start"} width={170}>
                    {!isEdit && (
                      <div id={`add-answer-${index1}`}>
                        <HeaderButton
                          type="secondary"
                          showIcon
                          onClick={() => {
                            setQuizData({
                              ...quizData,
                              questions: [
                                ...quizData.questions.slice(0, index1),
                                {
                                  ...question,
                                  options: [
                                    ...question.options,
                                    { answer: "" },
                                  ],
                                },
                                ...quizData.questions.slice(index1 + 1),
                              ],
                            });
                            setTimeout(() => {
                              let el = document.getElementById(
                                `add-answer-${index1}`
                              );
                              el?.scrollIntoView({ behavior: "smooth" });
                            }, 200);
                          }}
                        >
                          Add Answer
                        </HeaderButton>
                      </div>
                    )}
                  </Stack>
                  {quizData.errors.questions.find((x) => x === index1) ===
                    index1 && (
                    <FormHelperText error>
                      All fields are mandatory
                    </FormHelperText>
                  )}
                </Stack>
              </ComponentCard>
            ))}
            <Stack alignItems={"flex-start"}>
              {!isEdit && (
                <div ref={quesBtnRef}>
                  <BlockButton
                    onClick={() => {
                      setQuizData({
                        ...quizData,
                        questions: [
                          ...quizData.questions,
                          {
                            question: "",
                            options: [{ answer: "" }, { answer: "" }],
                            correct_answer: 0,
                          },
                        ],
                      });
                      setTimeout(() => {
                        quesBtnRef.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }, 200);
                    }}
                  >
                    Add Question
                  </BlockButton>
                </div>
              )}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      {/* <DialogActions
        sx={{
          bgcolor: "common.greyBg",
        }}
      >
        <Stack direction="row" spacing={2} p={2}>
          <HeaderButton type="secondary" onClick={onClose}>
            Cancel
          </HeaderButton>
          <HeaderButton
            type="primary"
            onClick={handleCreate}
            disabled={quizData.questions.find((q) => !q.question)}
          >
            {isEdit ? "Update" : "Create"}
          </HeaderButton>
        </Stack>
      </DialogActions> */}
    </Dialog>
  );
};

CreateQuiz.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  selQuiz: PropTypes.object,
  selMag: PropTypes.object,
};
