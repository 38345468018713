import theme from "../theme";

const builderOptions = [
  { type: "heading", title: "Heading", contentText: "", tag: "h3" },
  { type: "paragraph", title: "Paragraph", contentText: "", tag: "body1" },
  { type: "quote", title: "Quote", contentText: "", tag: "h6" },
];

const blockMenu = [
  {
    type: 1,
    type_name: "Category",
    isSingle: true,
    category: [],
    is_free: 1,
  },
  {
    type: 2,
    type_name: "Title",
    isSingle: true,
    text: "",
    text_lang: 2,
    is_free: 1,
    order: 1,
  },
  {
    type: 5,
    type_name: "Author",
    isSingle: true,
    author: {
      name: "",
      image: "",
      read_time: "",
      book_number: "",
    },
    is_free: 1,
    order: 1,
  },
  {
    type: 3,
    type_name: "Sub Title",
    isSingle: false,
    text: "",
    text_lang: 2,
    is_free: 0,
    order: 1,
  },
  {
    type: 4,
    type_name: "Paragraph",
    isSingle: false,
    text: "",
    text_lang: 2,
    is_free: 0,
    order: 1,
  },
  {
    type: 6,
    type_name: "Audio",
    isSingle: false,
    author: {
      name: "",
      image: "",
    },
    url: "",
    is_free: 0,
    order: 1,
  },
  {
    type: 7,
    type_name: "Image",
    isSingle: false,
    url: "",
    caption_text: "",
    caption_lang: 2,
    is_free: 0,
    order: 1,
  },
  {
    type: 8,
    type_name: "Video",
    isSingle: false,
    url: "",
    thumbnail: "",
    caption_text: "",
    caption_lang: 2,
    is_free: 0,
    order: 1,
  },
  {
    type: 9,
    type_name: "Quote",
    isSingle: false,
    pre_text: "",
    main_text: "",
    post_text: "",
    pre_text_lang: 2,
    main_text_lang: 2,
    post_text_lang: 2,
    is_free: 0,
    order: 1,
  },
  {
    type: 10,
    type_name: "Highlighted Quote",
    isSingle: false,
    text: "",
    text_lang: 2,
    is_free: 0,
    order: 1,
  },
  {
    type: 11,
    type_name: "Advertisement",
    isSingle: false,
    text: "",
    text_lang: 2,
    image: "",
    url: "",
    is_free: 0,
    order: 1,
  },
  {
    type: 12,
    type_name: "Link",
    isSingle: false,
    url: "",
    caption_text: "",
    caption_lang: 2,
    is_free: 0,
    order: 1,
  },
  {
    type: 0,
    type_name: "Divider",
    isSingle: false,
    full_width: 0,
    is_free: 0,
  },
];

const articleSettings = [
  { type: 1, title: "Free Article", key: "is_free", value: false },
  { type: 2, title: "Must Read", key: "must_read", value: false },
  { type: 3, title: "Featured Articles", key: "featured", value: false },
  { type: 4, title: "Trending Articles", key: "trending", value: false },
  { type: 5, title: "Top Articles", key: "top", value: false },
];

const passwordValidations = [
  { type: 1, title: "Minimum 8 characters in length", isValid: null },
  { type: 2, title: "Atleast 1 capital letter", isValid: null },
  { type: 3, title: "Atleast 1 small letter", isValid: null },
  { type: 4, title: "Atleast 1 number", isValid: null },
  { type: 5, title: "Atleast 1 special character", isValid: null },
];

const sideMenuOptions = [
  {
    type: 1,
    title: "Dashboard",
    icon: "ri-home-4-line",
    isSelected: true,
    link: "/dashboard",
  },
  {
    type: 2,
    title: "Magazines",
    icon: "ri-book-open-line",
    isSelected: false,
    link: "/magazines",
  },
  {
    type: 3,
    title: "Articles",
    icon: "ri-file-list-3-line",
    isSelected: false,
    link: "/articles",
  },
  {
    type: 3.1,
    title: "Free Articles",
    icon: "ri-file-list-3-line",
    isSelected: false,
    link: "/free-articles",
  },
  {
    type: 3.2,
    title: "Quizzes",
    icon: "ri-question-line",
    isSelected: false,
    link: "/quizzes",
  },
  {
    type: 4,
    title: "Authors",
    icon: "ri-pen-nib-line",
    isSelected: false,
    link: "/authors",
  },
  {
    type: 5,
    title: "Categories",
    icon: "ri-list-check",
    isSelected: false,
    link: "/categories",
  },
  {
    type: 6,
    title: "Users",
    icon: "ri-user-3-line",
    isSelected: false,
    link: "/users",
  },
  {
    type: 7,
    title: "Languages",
    icon: "ri-earth-line",
    isSelected: false,
    link: "/languages",
  },
  // {
  //   type: 8,
  //   title: "Plans",
  //   icon: "ri-vip-crown-2-line",
  //   isSelected: false,
  //   link: "/plans",
  // },
  {
    type: 9,
    title: "Advertisements",
    icon: "ri-file-image-line",
    isSelected: false,
    link: "/advertisements",
  },
  {
    type: 10,
    title: "Quotes",
    icon: "ri-chat-quote-line",
    isSelected: false,
    link: "/quotes",
  },
  // {
  //   type: 10,
  //   title: "Settings",
  //   icon: "ri-settings-3-line",
  //   isSelected: false,
  // },
  {
    type: 11,
    title: "Feedbacks",
    icon: "ri-chat-2-line",
    isSelected: false,
    link: "/feedback",
  },
  {
    type: 12,
    title: "Logout",
    icon: "ri-logout-circle-line",
    isSelected: false,
  },
];

const dashboardCounterOptions = [
  {
    type: 1,
    title: "Published Magazines",
    icon: "ri-book-open-line",
    color: theme.palette.common.redTxt,
    count: 43,
  },
  {
    type: 2,
    title: "Published Articles",
    icon: "ri-file-list-3-line",
    color: theme.palette.common.greenTxt,
    count: 765,
  },
  {
    type: 3,
    title: "No. of Authors",
    icon: "ri-pen-nib-line",
    color: theme.palette.common.blueTxt,
    count: 31,
  },
  {
    type: 4,
    title: "No. of Subscribers",
    icon: "ri-vip-crown-2-line",
    color: theme.palette.common.orangeTxt,
    count: 20986,
  },
];

const categories = [
  {
    id: "f1dd68e7-72ef-4098-886b-a1025e5f181f",
    category_colour: "#1A237E",
    descriptions: [
      {
        category_name: "സംസ്കരണം",
        language_id: 2,
      },
    ],
  },
  {
    id: "88e9ca20-9dc6-4b64-9ca4-7b20e64c243d",
    category_colour: "#D84315",
    descriptions: [
      {
        category_name: "ഇഫ്‌സാൻ",
        language_id: 2,
      },
    ],
  },
  {
    id: "30e08369-6711-4d30-87fa-7c9a3a879857",
    category_colour: "#E91E63",
    descriptions: [
      {
        category_name: "തസ്കിയ",
        language_id: 2,
      },
    ],
  },
  {
    id: "8b12c91b-5848-4ccd-abac-2fafe9c78e75",
    category_colour: "#7C4DFF",
    descriptions: [
      {
        category_name: "സൗഹൃദം",
        language_id: 2,
      },
    ],
  },
  {
    id: "6de4a884-6a61-4c82-b74c-5fcfd2655a0a",
    category_colour: "#673AB7",
    descriptions: [
      {
        category_name: "കാഴ്ചപ്പാട്",
        language_id: 2,
      },
    ],
  },
  {
    id: "e65e4153-647e-455c-9c2d-788490ac409c",
    category_colour: "#FF3D00",
    descriptions: [
      {
        category_name: "സന്ദേശം",
        language_id: 2,
      },
    ],
  },
  {
    id: "c20597cd-7e88-4b4d-9656-515d0dfb1cfd",
    category_colour: "#455A64",
    descriptions: [
      {
        category_name: "വിമർശനം",
        language_id: 2,
      },
    ],
  },
  {
    id: "9415c633-596a-40c2-8f05-0048302addf7",
    category_colour: "#1976D2",
    descriptions: [
      {
        category_name: "ആത്മീയം",
        language_id: 2,
      },
    ],
  },
  {
    id: "d55bbbd5-dd0e-4272-94d8-534ebd30ed13",
    category_colour: "#311B92",
    descriptions: [
      {
        category_name: "ആസ്വാദനം",
        language_id: 2,
      },
    ],
  },
  {
    id: "b9a47baa-906a-4390-8b8a-e5ec0b156084",
    category_colour: "#9C27B0",
    descriptions: [
      {
        category_name: "വ്യക്തിമുദ്ര",
        language_id: 2,
      },
    ],
  },
  {
    id: "bd8f6865-c1a2-444d-ab36-f28837a03b61",
    category_colour: "#F57F17",
    descriptions: [
      {
        category_name: "ഇടപെടൽ",
        language_id: 2,
      },
    ],
  },
  {
    id: "7c6e6190-209a-4495-940f-3a153b695f3f",
    category_colour: "#00695C",
    descriptions: [
      {
        category_name: "കാലികം",
        language_id: 2,
      },
    ],
  },
  {
    id: "a8e30811-a2b8-4390-b5fb-afe88ae44428",
    category_colour: "#3F51B5",
    descriptions: [
      {
        category_name: "ചുറ്റുപാട്",
        language_id: 2,
      },
    ],
  },
  {
    id: "406fc814-a4fc-4bc0-9d8e-1c84b2d61342",
    category_colour: "#536DFE",
    descriptions: [
      {
        category_name: "ഓർമ / അനുസ്മരണം",
        language_id: 2,
      },
    ],
  },
  {
    id: "da0a85ae-d4ed-4e58-93db-98cc7f7d0374",
    category_colour: "#004D40",
    descriptions: [
      {
        category_name: "ശാസ്ത്രം",
        language_id: 2,
      },
    ],
  },
  {
    id: "2dcb3be4-8a6e-4b59-b6ca-a106af9cde9c",
    category_colour: "#D50000",
    descriptions: [
      {
        category_name: "പൈതൃകം",
        language_id: 2,
      },
    ],
  },
  {
    id: "d1afebfa-d7ed-4b6a-8e70-6684dd1ac371",
    category_colour: "#33691E",
    descriptions: [
      {
        category_name: "പഠനം",
        language_id: 2,
      },
    ],
  },
  {
    id: "6e8fddd9-e8db-47e4-99b5-aa15f8f1fe0f",
    category_colour: "#E65100",
    descriptions: [
      {
        category_name: "ഇസ്ലാം @ മീഡിയ",
        language_id: 2,
      },
    ],
  },
  {
    id: "1ddce81e-1535-419e-8d00-8c76203dd8d1",
    category_colour: "#F44336",
    descriptions: [
      {
        category_name: "സഹയാത്രിക",
        language_id: 2,
      },
    ],
  },
  {
    id: "fb7ae971-ce1f-4882-9e8f-5f1218537b5c",
    category_colour: "#827717",
    descriptions: [
      {
        category_name: "തിരുനബി പഠനം",
        language_id: 2,
      },
    ],
  },
  {
    id: "b019748b-c1ee-4bb2-bbdd-f63d08359db0",
    category_colour: "#006064",
    descriptions: [
      {
        category_name: "ഫീച്ചർ",
        language_id: 2,
      },
    ],
  },
  {
    id: "ce3a8f7e-f999-4797-bc85-90bb76bf0d6b",
    category_colour: "#5D4037",
    descriptions: [
      {
        category_name: "നിവാരണം",
        language_id: 2,
      },
    ],
  },
  {
    id: "49c3a394-16ba-4115-8254-ab32d2b6fad9",
    category_colour: "#01579B",
    descriptions: [
      {
        category_name: "തിരുനോട്ടം",
        language_id: 2,
      },
    ],
  },
  {
    id: "cdee2950-fa17-4e7e-89f6-e7642c98b653",
    category_colour: "#7C4DFF",
    descriptions: [
      {
        category_name: "പ്രമാണം",
        language_id: 2,
      },
    ],
  },
  {
    id: "d604d19e-9ed0-42ed-a066-acd94dec812b",
    category_colour: "#3F51B5",
    descriptions: [
      {
        category_name: "സംസ്കാരം",
        language_id: 2,
      },
    ],
  },
  {
    id: "1adce85d-ce86-43d7-b9eb-60ac40ed48f9",
    category_colour: "#2E7D32",
    descriptions: [
      {
        category_name: "ആദർശം",
        language_id: 2,
      },
    ],
  },
  {
    id: "32970e39-2a13-4a36-a9c7-24984a0d4df2",
    category_colour: "#546E7A",
    descriptions: [
      {
        category_name: "സംസാരം",
        language_id: 2,
      },
    ],
  },
  {
    id: "554a1656-18e4-478e-826f-38c0def2e444",
    category_colour: "#2962FF",
    descriptions: [
      {
        category_name: "പ്രൈംസ്റ്റോറി ",
        language_id: 2,
      },
    ],
  },
  {
    id: "249ba56e-7e12-4d19-bde2-1525389fab89",
    category_colour: "#FF6D00",
    descriptions: [
      {
        category_name: "ദാര്‍ശനികം",
        language_id: 2,
      },
    ],
  },
  {
    id: "a98a2d36-3715-48f5-a17a-b2dbed01375c",
    category_colour: "#673AB7",
    descriptions: [
      {
        category_name: "ഖുര്‍ആന്‍ വിസ്മയങ്ങള്‍",
        language_id: 2,
      },
    ],
  },
  {
    id: "af195921-0539-4e95-9c0f-2326ad32edf5",
    category_colour: "#64DD17",
    descriptions: [
      {
        category_name: "സാമൂഹികം",
        language_id: 2,
      },
    ],
  },
  {
    id: "7510ad40-12da-40e7-a7c4-80f6091dfcfe",
    category_colour: "#5D4037",
    descriptions: [
      {
        category_name: "ചരിത്രപഥം",
        language_id: 2,
      },
    ],
  },
  {
    id: "bde590e9-c4b6-4bbc-a5a2-f56b7e159566",
    category_colour: "#64DD17",
    descriptions: [
      {
        category_name: "സംഘചലനം",
        language_id: 2,
      },
    ],
  },
];

const authors = [
  {
    id: "54d70ae6-f856-4180-8dba-1552ac858d8a",
    author_name: "പൊന്മള അബ്ദുല്‍ ഖാദിര്‍ മുസ്‌ലിയാർ",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428236.46647_ponmala.jpg",
  },
  {
    id: "a2614c1c-112a-4437-b32b-e7d4c98a9a78",
    author_name: "സയ്യിദ് ഇബ്‌റാഹീമുല്‍ ഖലീലുല്‍ ബുഖാരി",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428213.2638_sayeed.jpg",
  },
  {
    id: "d313e791-0934-4c44-a715-1f5e9d605409",
    author_name: " ഡോ. അബ്ദുല്‍ ഹകീം അസ്ഹരി ",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428182.8457465_Dr.abdul.jpg",
  },
  {
    id: "9f3c4c44-1a4f-41a6-9b71-453d9001d5af",
    author_name: " സൈനുദ്ദീന്‍ ശാമില്‍ ഇര്‍ഫാനി മാണൂര്‍ ",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "f07ba824-6665-439a-8149-d5b352f66363",
    author_name: " അലി സഖാഫി പുല്‍പറ്റ ",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "00c47f6a-7ba7-44c6-83ab-c8b21dedfbd6",
    author_name: "അസ്‌ലം സഖാഫി പയ്യോളി",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "34e0776d-13b0-4d21-a66b-fd5686577b1a",
    author_name: "അസീസ് സഖാഫി വാളക്കുളം",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "1c9ca96d-4849-4e3b-b0cd-bc14e14c4af6",
    author_name: "ജുനൈദ് ഖലീല്‍ നൂറാനി",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "adc572f9-72a7-4326-a243-90289d3efdad",
    author_name: "ഡോ. ഉമറുല്‍ ഫാറൂഖ് സഖാഫി കോട്ടുമല",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428029.3461645_Umarul%20farooq.jpg",
  },
  {
    id: "22cf1deb-3207-4955-8c18-a9bf369b8d35",
    author_name: "സുലൈമാന്‍ ഫൈസി കിഴിശ്ശേരി",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "4b1649f4-f195-44a1-bea7-d63c046afbac",
    author_name: "അലവിക്കുട്ടി ഫൈസി എടക്കര",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "c34546c6-5be9-4e0f-b6b5-47386257d516",
    author_name: "മുഹമ്മദ് റാസി നൂറാനി അസ്സഖാഫി",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "09626745-72d6-4b2c-8cff-4827bfc5bbbe",
    author_name: " ഡോ. അബൂബക്കര്‍ നിസാമി കളരാന്തിരി",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "acaade20-13bc-492e-ac0a-b4167819085d",
    author_name: "ഇസ്ഹാഖ് അഹ്‌സനി",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "5a100d62-f259-4770-b758-bcc5bb50029a",
    author_name: "ആബിദ് കുണിയ",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "40801503-e38a-4dec-a479-883e0dd2fd19",
    author_name: "ഡോ. മുഹമ്മദ് ഫാറൂഖ് നഈമി",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428148.2606986_Mohammed%20farooq.jpg",
  },
  {
    id: "ef5c728e-cb67-4293-bfb3-a3726d9bd888",
    author_name: "കാന്തപുരം എ.പി അബൂബക്കര്‍ മുസ്‌ലിയാർ",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428267.3887475_Kanthapuram.jpg",
  },
  {
    id: "4acd9bb6-61d5-4ecc-bc2e-9cf73b30a734",
    author_name: "ഡോ. ശൈഖ് ഉസാമ അല്‍ അസ്ഹരി",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "11781362-7919-4b65-92e4-613266d0f11d",
    author_name: "ഉമൈര്‍ ബുഖാരി",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "8f260511-28c3-4aa3-9948-5287906f4256",
    author_name: "മുഹമ്മദ് നാഫില്‍ അരീക്കല്‍",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "51d5e0d8-fb0e-46e3-a531-f239c813bbc4",
    author_name: "ഡോ. പി മുജീബ് റഹ്മാന്‍",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "be040b11-5f24-47e0-8bd0-b130008f29e6",
    author_name: "അംജദ് അലി നൂറാനി ഓമശ്ശേരി",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "5b9b3fe5-ada9-42c3-9d65-5c1755ead7e2",
    author_name: "അബൂഅറഫാത്ത് വെന്നിയൂര്‍",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "a2a6b330-0d45-4072-a997-f7d4ec55710a",
    author_name: "ഹുസൈന്‍ തങ്ങള്‍ വാടാനപ്പള്ളി",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
  {
    id: "3068c4d9-9ed0-472c-9ff1-8d07f9082672",
    author_name: "മുഹമ്മദ് ത്വാഹിര്‍ എ",
    author_image:
      "https://sunnivoiceresources.s3.amazonaws.com/media/1699428540.337612_authorplaceholder.jpg",
  },
];

const languages = [
  {
    id: 1,
    name: "English",
    short_code: "en",
  },
  {
    id: 2,
    name: "Malayalam",
    short_code: "ml",
  },
  {
    id: 3,
    name: "Arabic",
    short_code: "ar",
  },
];

const newArticle = {
  title: "",
  title_lang: 2,
  feature_image: "",
  author_id: "",
  author_name: "",
  author_image: "",
  is_free: false,
  must_read: false,
  ready_to_publish: false,
  top: false,
  featured: false,
  trending: false,
  article_categories: [],
  keywords: [],
  components: [
    {
      type: 1,
      type_name: "Category",
      category: [],
      is_free: 1,
      order: 1,
    },
    {
      type: 2,
      type_name: "Title",
      text: "",
      text_lang: 2,
      is_free: 1,
      order: 1,
    },
    {
      type: 5,
      type_name: "Author",
      author: {
        name: "",
        image: "",
        read_time: "",
        book_number: "",
        year_number: "",
      },
      is_free: 1,
      order: 1,
    },
    {
      type: 0,
      type_name: "Divider",
      full_width: 1,
      is_free: 1,
      order: 1,
    },
  ],
  errors: {
    title: false,
    categories: false,
    author: false,
    keywords: false,
    feature_image: false,
    read_time: false,
    invalid_components: [],
  },
};

export {
  builderOptions,
  blockMenu,
  articleSettings,
  passwordValidations,
  sideMenuOptions,
  dashboardCounterOptions,
  categories,
  authors,
  languages,
  newArticle,
};
