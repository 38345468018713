import React from "react";
import { Modal, Grid, Typography, Stack, Box, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HeaderButton } from "../../../components";
import PropTypes from "prop-types";
import { api, urls } from "../../../services";
import { useAuth, useToast } from "../../../contexts";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 700,
    background: theme.palette.common.greyBg,
    boxShadow: 24,
    padding: theme.spacing(4),
    borderRadius: 6,
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
  },
  divider: {
    "&.MuiDivider-root": {
      width: "100%",
      marginBottom: theme.spacing(3),
    },
  },
  coverImage: {
    width: 230,
    height: 280,
    objectFit: "cover",
    borderRadius: 4,
  },
  regularLabel: {
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 400,
    },
  },
  mediumLabel: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  bookLabel: {
    "&.MuiTypography-root": {
      color: theme.palette.theme.main,
      fontSize: 16,
      fontWeight: 500,
    },
  },
}));

export function PublishMagazine(props) {
  const classes = useStyles();
  const { open, onClose, onSubmit, magData } = props;
  const { logout } = useAuth();
  const { showToast } = useToast();

  const handleCancelClick = () => {
    onClose();
  };

  const handleConfirmClick = () => {
    let params = {
      released: true,
      release_date: moment().format("YYYY-MM-DD"),
    };
    api
      .post(urls.publishMagazine + magData.id, params)
      .then((res) => {
        showToast(res.data.message, "success");
        onSubmit();
      })
      .catch((err) => {
        showToast(err.response?.data?.message, "error");
      });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.root}>
        <Grid container spacing={2} paddingBottom={3} alignItems="center">
          <Grid item>
            <Typography className={classes.titleText}>
              Publish Magazine
            </Typography>
          </Grid>
          <Grid item xs />
          <Grid item sx={{ width: 150 }}>
            <HeaderButton type="secondary" onClick={handleCancelClick}>
              Cancel
            </HeaderButton>
          </Grid>
          <Grid item sx={{ width: 150 }}>
            <HeaderButton type="primary" onClick={handleConfirmClick}>
              Confirm
            </HeaderButton>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={4}>
          <Grid item>
            <img
              alt="mag1"
              src={magData?.cover_image}
              className={classes.coverImage}
            />
          </Grid>
          <Grid item xs>
            <Grid container direction="column" spacing={3}>
              <Grid item xs>
                <Stack direction="row" spacing={10}>
                  <Stack>
                    <Typography className={classes.regularLabel}>
                      Issue
                    </Typography>
                    <Typography className={classes.mediumLabelLabel}>
                      {magData?.issue_name}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography className={classes.regularLabel}>
                      Book Number
                    </Typography>
                    <Typography className={classes.bookLabel}>
                      {`പുസ്തകം ${magData?.book_number} - ${magData?.year_number}`}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs>
                <Divider />
              </Grid>
              <Grid item xs>
                <Stack direction="row" spacing={10}>
                  <Stack>
                    <Typography className={classes.regularLabel}>
                      Magazine Category
                    </Typography>
                    <Typography className={classes.mediumLabelLabel}>
                      {magData?.product_set_name}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography className={classes.regularLabel}>
                      Price
                    </Typography>
                    <Typography className={classes.mediumLabel}>
                      {`₹ ${magData?.amount ?? "--"}`}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs>
                <Divider />
              </Grid>
              <Grid item xs>
                <Stack direction="row" spacing={10}>
                  <Stack>
                    <Typography className={classes.regularLabel}>
                      No. of Articles
                    </Typography>
                    <Typography className={classes.mediumLabelLabel}>
                      {magData?.article_list?.length ?? 0}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

PublishMagazine.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  magData: PropTypes.object,
};
