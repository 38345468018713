// import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PropTypes from "prop-types";
import { Stack, FormHelperText } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export function DateTextField(props) {
  const classes = useStyles();
  const {
    label,
    onChange,
    value,
    error,
    helperText,
    minDate,
    disabled,
    maxDate,
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack className={classes.root}>
        <DatePicker
          id="normal-field"
          className={classes.root}
          label={label}
          onChange={onChange}
          variant="outlined"
          format="DD MMM YYYY"
          value={value}
          error={error}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
        />
        {error && (
          <FormHelperText sx={{ width: "100%" }} error>
            {helperText}
          </FormHelperText>
        )}
      </Stack>
    </LocalizationProvider>
  );
}

DateTextField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
};

DateTextField.defaultProps = {
  disabled: false,
  minDate: null,
  maxDate: null,
};
