import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "&.MuiButton-root": {
      fontSize: 16,
      fontWeight: 600,
      color: "white",
      background: theme.palette.theme.main,
      "&:hover": {
        background: theme.palette.theme.main,
      },
      height: 50,
    },
  },
}));

export function ActionButton(props) {
  const classes = useStyles();
  const { children, onClick } = props;

  return (
    <Button className={classes.root} onClick={onClick}>
      {children}
    </Button>
  );
}

ActionButton.propTypes = {
  onClick: PropTypes.func,
};
