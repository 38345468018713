import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  Stack,
} from "@mui/material";
import { HeaderButton, NormalTextField } from "../../../components";
import { api, urls } from "../../../services";
import { useAuth, useToast } from "../../../contexts";

export const SendNotification = (props) => {
  const { open, onClose, onSubmit, selArticle } = props;
  const { logout } = useAuth();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    message: "",
    errors: {
      title: false,
      message: false,
    },
  });

  const handleSubmit = () => {
    let invalid = false;
    if (formData.title.trim() === "") {
      setFormData({
        ...formData,
        errors: {
          ...formData.errors,
          title: true,
        },
      });
      invalid = true;
    }
    if (formData.message.trim() === "") {
      setFormData({
        ...formData,
        errors: {
          ...formData.errors,
          message: true,
        },
      });
      invalid = true;
    }

    if (!invalid) {
      setLoading(true);
      let params = {
        title: `Pravasi Vayana - ${selArticle.title} - ${formData.title}`,
        message: formData.message,
      };
      api
        .post(urls.sendArticleNotification + selArticle.id, params)
        .then((res) => {
          setLoading(true);
          showToast(res.data.message, "success");
          onClose();
          onSubmit();
        })
        .catch((err) => {
          setLoading(true);
          if (err.response?.status === 401) {
            logout();
          } else {
            showToast(err.response?.data?.message, "error");
          }
        });
    }
  };

  return (
    <Dialog open={open} sx={{ p: 4 }} fullWidth>
      <DialogTitle textAlign={"center"}>Send Notification</DialogTitle>
      <DialogContent>
        <Container
          maxWidth="md"
          sx={{
            justifyContent: "center",
            py: 2,
          }}
        >
          <Stack alignItems={"center"} spacing={2}>
            <NormalTextField
              label="Title"
              multiline
              value={formData.title}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  title: e.target.value,
                  errors: { ...formData.errors, title: false },
                })
              }
              error={formData.errors.title}
              helperText={formData.errors.title ? "Enter title" : ""}
            />
            <NormalTextField
              label="Message"
              multiline
              rows={3}
              value={formData.message}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  message: e.target.value,
                  errors: { ...formData.errors, message: false },
                })
              }
              charLimit={200}
              error={formData.errors.message}
              helperText={formData.errors.message ? "Enter message" : ""}
            />
          </Stack>
        </Container>
      </DialogContent>
      <DialogActions sx={{ p: 4, pt: 0, justifyContent: "center" }}>
        <Stack
          spacing={2}
          direction="row"
          sx={{
            width: 300,
          }}
        >
          <HeaderButton type="secondary" onClick={onClose}>
            Cancel
          </HeaderButton>
          <HeaderButton
            type="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            Submit
          </HeaderButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

SendNotification.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  selArticle: PropTypes.object,
};
