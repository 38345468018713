import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  coverImage: {
    width: 195,
    height: 250,
    borderRadius: 6,
    objectFit: "cover",
  },
  bookLabel: {
    "&.MuiTypography-root": {
      color: theme.palette.theme.main,
      fontSize: 13,
      fontWeight: 500,
    },
  },
  nameLabel: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 600,
    },
  },
}));

export function MagazineDashboardItem(props) {
  const classes = useStyles();
  const { noImage, cover, book, name, onItemClick } = props;

  return (
    <Box sx={{ cursor: "pointer" }} onClick={onItemClick}>
      <Stack spacing={2}>
        {noImage === true ? null : (
          <img alt="mag" src={cover} className={classes.coverImage} />
        )}
        <Stack>
          <Typography
            className={classes.bookLabel}
          >{`പുസ്തകം ${book}`}</Typography>
          <Typography className={classes.nameLabel}>{name}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

MagazineDashboardItem.propTypes = {
  noImage: PropTypes.bool,
  cover: PropTypes.string,
  book: PropTypes.string,
  name: PropTypes.string,
  onItemClick: PropTypes.func,
};
