import React from "react";
import { Autocomplete, TextField, Box } from "@mui/material";
import { countriesList } from "../../assets";
import PropTypes from "prop-types";

export const CountryTextField = (props) => {
  const { value, onChange, error, helperText, label } = props;

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      id="country-select-demo"
      options={countriesList}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          error={error}
          helperText={error ? helperText : ""}
        />
      )}
      onSelect={onChange}
      value={countriesList.find((c) => c.label === value)}
    />
  );
};

CountryTextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
};
