import React, { Fragment, useCallback, useState } from "react";
import {
  Grid,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Box,
} from "@mui/material";
import {
  ListingHeader,
  ContainerCard,
  MagazineTableItem,
  StatusLabel,
  PublishedLabel,
  ArticleTableItem,
  AuthorTableItem,
} from "../../components";
import { makeStyles } from "@mui/styles";
import { AddLanguage } from "../popups/AddLanguage";
import dotted from "../../assets/images/dottedmore.svg";
import { useAuth, useToast } from "../../contexts";
import { api, urls } from "../../services";
import { useEffect } from "react";
import { LanguageTableItem } from "../../components/items/LanguageTableItem";
import LoaderCard from "../../components/cards/LoaderCard";

// import { languages } from "../../utilities";
const useStyles = makeStyles((theme) => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  header: {
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
  border: {
    borderBottom: "0px !important",
  },
}));

let totalLanguage = [];

export function Languages() {
  const classes = useStyles();
  const [openAddLanguage, setOpenLanguage] = useState(false);
  const [languages, setLanguages] = useState([]);
  const { showToast } = useToast();
  const { logout } = useAuth();
  const [totalPage, setTotalPages] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleOnSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const handleCloseAddLanguage = () => {
    setOpenLanguage(false);
  };
  const handleOnclickLanguage = () => {
    setOpenLanguage(true);
  };

  const getLanguage = () => {
    setIsLoading(true);
    api
      .get(urls.languages)
      .then((res) => {
        setLanguages(res.data.data);
        setSearchInput("");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getLanguage();
  }, []);

  useEffect(() => {
    let filteredLanguage = [];

    searchInput === ""
      ? (filteredLanguage = [...totalLanguage])
      : totalLanguage.map((item) => {
          item.name.toLowerCase().includes(searchInput.toLowerCase()) &&
            filteredLanguage.push(item);
        });

    setLanguages(filteredLanguage);
  }, [searchInput]);

  return (
    <Fragment>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <ListingHeader
            title={
              languages.length > 0
                ? `Languages (${languages.length})`
                : "Languages"
            }
            // btnTitle="Add New Language"
            // placeholder="Search Languages"
            onClickPrimary={handleOnclickLanguage}
            searchInput={searchInput}
            onSearchChange={handleOnSearch}
          />
        </Grid>
        {isLoading && (
          <Grid item>
            <LoaderCard />
          </Grid>
        )}
        {!isLoading && (
          <Grid item>
            <ContainerCard>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.header}>Language</TableCell>
                      <TableCell className={classes.header}>
                        Language Code
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {languages?.map((post, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell>
                            {<LanguageTableItem language={post.name} />}
                          </TableCell>

                          <TableCell>{post.short_code}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </ContainerCard>
          </Grid>
        )}
      </Grid>
      <AddLanguage open={openAddLanguage} onClose={handleCloseAddLanguage} />
    </Fragment>
  );
}
