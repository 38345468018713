import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  Stack,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { HeaderButton } from "../../../components";
import { SimCardDownload, CloudUpload } from "@mui/icons-material";
import { uploadUsersSample } from "../../../assets";
import { api, urls } from "../../../services";
import { useAuth, useToast } from "../../../contexts";

export const ExcelUpload = (props) => {
  const { open, onClose, onSubmit } = props;
  const { logout } = useAuth();
  const { showToast } = useToast();
  const uplodRef = useRef(null);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const extension = file.name.split(".").pop();
      if (extension !== "xls" || extension !== "xlsx") {
        showToast("Only .xls or .xlsx files are allowed", "error");
        return;
      }

      setUploading(true);

      const formData = new FormData();
      formData.append("excel_file", file);

      api
        .post(urls.excelUserUpload, formData)
        .then((res) => {
          setUploading(false);
          showToast(res.data.message, "success");
          onClose();
          onSubmit();
        })
        .catch((err) => {
          setUploading(false);
          if (err.response?.status === 401) {
            logout();
          } else {
            showToast(err.response?.data?.message, "error");
          }
        });
    }
  };

  return (
    <Dialog open={open} sx={{ p: 4 }} fullWidth>
      <DialogTitle textAlign={"center"}>Users Upload Excel</DialogTitle>
      <DialogContent>
        <Container
          maxWidth="md"
          sx={{
            justifyContent: "center",
          }}
        >
          <Stack alignItems={"center"} spacing={2}>
            {!uploading && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <a href={uploadUsersSample} download="upload-users-sample.xlsx">
                  <Button
                    sx={{ textTransform: "none" }}
                    startIcon={<SimCardDownload />}
                  >
                    Download Template
                  </Button>
                </a>

                <Button
                  sx={{ textTransform: "none" }}
                  startIcon={<CloudUpload />}
                  onClick={() => uplodRef.current.click()}
                >
                  Upload File
                  <input
                    type="file"
                    hidden
                    accept=".xls, .xlsx"
                    onChange={handleFileChange}
                    ref={uplodRef}
                  />
                </Button>
              </Stack>
            )}

            {uploading && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <CircularProgress />
                <Typography>Uploading...</Typography>
              </Stack>
            )}
          </Stack>
        </Container>
      </DialogContent>
      <DialogActions sx={{ p: 4, pt: 0 }}>
        <Stack spacing={2} direction="row">
          <HeaderButton type="secondary" onClick={onClose} disabled={uploading}>
            Cancel
          </HeaderButton>
          {/* <HeaderButton type="primary" onClick={onSubmit}>
            Upload
          </HeaderButton> */}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

ExcelUpload.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
