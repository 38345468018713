import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Typography,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  MagazineDetailsHeader,
  ContainerCard,
  ArticleTableItem,
  StatusLabel,
  HeaderButton,
  NormalCard,
} from "../../components";
import {
  PublishMagazine,
  CreateArticle,
  CreateMagazine,
  CreateQuiz,
} from "../../pages";
import { useParams, useNavigate } from "react-router-dom";
import { api, urls } from "../../services";
import { useToast, useAuth } from "../../contexts";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { useModal } from "../../hooks";

const useStyles = makeStyles((theme) => ({
  statusItem: {
    "&.MuiTableCell-root": {
      // display: "flex",
      // justifyContent: "flex-start",
      // alignItems: "center",
      // minHeight: 60,
    },
  },
  imageCover: {
    width: 95,
    height: 125,
    objectFit: "cover",
  },
  regularLabel: {
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 400,
    },
  },
  mediumLabel: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  bookLabel: {
    "&.MuiTypography-root": {
      color: theme.palette.theme.main,
      fontSize: 16,
      fontWeight: 500,
    },
  },
  buttonContainer: {
    "&.MuiBox-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
  },
  header: {
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
}));

export function MagazineDetails() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openPublishMag, setOpenPublishMag] = useState(false);
  const [openCreateArt, setOpenCreateArt] = useState(false);
  const { magID } = useParams();
  const { showToast } = useToast();
  const { logout } = useAuth();
  const [magData, setMagData] = useState(null);
  const [selArtID, setSelArtID] = useState("");
  const [openEditMag, setOpenEditMag] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [isOpenQuizModal, openQuizModal, closeQuizModal] = useModal();

  const getMagazineDetails = () => {
    api
      .get(urls.magazineDetails + magID)
      .then((res) => {
        setMagData(res.data.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const handleClosePublishMag = () => {
    setOpenPublishMag(false);
  };

  const handleOnClickPrimary = () => {
    let draft = magData?.article_list?.filter(
      (x) => x.ready_to_publish === false
    );
    if (draft.length === 0) {
      setOpenPublishMag(true);
    } else {
      showToast(`Can't publish magazine with draft articles`, "error");
    }
  };

  const handleCloseCreatArt = () => {
    setOpenCreateArt(false);
    setSelArtID(null);
    getMagazineDetails();
  };

  const handleOnClickCreateArt = () => {
    setOpenCreateArt(true);
  };

  const handleArticleClick = (id) => {
    setSelArtID(id);
    setOpenCreateArt(true);
  };

  const handleOnClickCreateQuiz = () => {
    if (magData?.quiz_id) {
      navigate(`/quizzes/${magData.quiz_id}`);
    } else {
      openQuizModal();
    }
  };

  useEffect(() => {
    getMagazineDetails();
  }, []);

  const getArticleTypes = (item) => {
    let list = [];
    if (item.top) {
      list.push("Top");
    }
    if (item.trending) {
      list.push("Trending");
    }
    if (item.featured) {
      list.push("Featured");
    }
    if (item.must_read) {
      list.push("Must Read");
    }
    return list.length > 0 ? list.join(", ") : "--";
  };

  const handleMenuOptionClick = (m) => {
    console.log("🚀 ~ handleMenuOptionClick ~ m:", m);
    if (m.id === 1) {
      setOpenEditMag(true);
    } else {
      //delete
      setOpenDeleteConfirm(true);
    }
  };

  const onConfirmDeletion = () => {
    setOpenDeleteConfirm(false);
    api
      .delete(urls.deleteMagazine + magID)
      .then((res) => {
        navigate(-1);
        showToast("Magazine deleted successfully", "success");
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const handleCloseEditMag = () => {
    setOpenEditMag(false);
  };

  const handleReloadMagazine = () => {
    handleClosePublishMag();
    handleCloseCreatArt();
    handleCloseEditMag();
    getMagazineDetails();
  };

  return (
    <Fragment>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <MagazineDetailsHeader
            title={magData?.issue_name}
            onClickPrimary={handleOnClickPrimary}
            isDraft={!(magData?.released ?? true)}
            onMenuOptionClick={handleMenuOptionClick}
            disableBtn={magData?.article_list?.length === 0}
          />
        </Grid>
        <Grid item>
          <ContainerCard>
            <Grid container spacing={4} padding={1}>
              <Grid item>
                <img
                  alt="mag"
                  src={magData?.cover_image}
                  className={classes.imageCover}
                />
              </Grid>
              <Grid item>
                <Stack
                  justifyContent="space-between"
                  alignItems="stretch"
                  sx={{ height: "100%" }}
                >
                  <Stack>
                    <Typography className={classes.regularLabel}>
                      Issue
                    </Typography>
                    <Typography className={classes.mediumLabelLabel}>
                      {magData?.issue_name}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography className={classes.regularLabel}>
                      Book Number
                    </Typography>
                    <Typography className={classes.bookLabel}>
                      {`പുസ്തകം ${magData?.book_number} - ${magData?.year_number}`}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item>
                <Stack
                  justifyContent="space-between"
                  alignItems="stretch"
                  sx={{ height: "100%" }}
                >
                  <Stack>
                    <Typography className={classes.regularLabel}>
                      Magazine Category
                    </Typography>
                    <Typography className={classes.mediumLabelLabel}>
                      {magData?.product_set_name}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography className={classes.regularLabel}>
                      Price
                    </Typography>
                    <Typography className={classes.mediumLabel}>
                      {`₹ ${magData?.amount ?? "--"}`}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs />
              {magData && (
                <Grid item>
                  <Box className={classes.buttonContainer}>
                    <HeaderButton
                      type="secondary"
                      showIcon={magData?.quiz_id ? false : true}
                      onClick={handleOnClickCreateQuiz}
                    >
                      {magData?.quiz_id ? "View Quiz" : "Create Quiz"}
                    </HeaderButton>
                  </Box>
                </Grid>
              )}
              <Grid item>
                <Box className={classes.buttonContainer}>
                  <HeaderButton
                    type="primary"
                    showIcon={true}
                    onClick={handleOnClickCreateArt}
                  >
                    Add Article
                  </HeaderButton>
                </Box>
              </Grid>
            </Grid>
          </ContainerCard>
        </Grid>
        <Grid item>
          <NormalCard
            title={`Articles (${magData?.article_list?.length ?? "--"})`}
          >
            {magData?.article_list?.length > 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ fontSize: 16, fontWeight: 600 }}>
                      <TableCell className={classes.header}>#</TableCell>
                      <TableCell className={classes.header}>Article</TableCell>
                      <TableCell className={classes.header}>Author</TableCell>
                      <TableCell className={classes.header}>Category</TableCell>
                      <TableCell className={classes.header}>Type</TableCell>
                      <TableCell className={classes.header} align="center">
                        Status
                      </TableCell>
                      <TableCell className={classes.header}>Include</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {magData?.article_list?.map((item, i) => (
                      <TableRow
                        sx={{ cursor: "pointer" }}
                        hover
                        onClick={() => handleArticleClick(item.id)}
                      >
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>
                          {
                            <ArticleTableItem
                              image={item.feature_image}
                              title={item.title}
                            />
                          }
                        </TableCell>
                        <TableCell>{item.author_name}</TableCell>
                        <TableCell>{item.category_name.join(",")}</TableCell>
                        <TableCell>
                          <StatusLabel type={item.is_free ? "free" : "paid"}>
                            {item.is_free ? "Free" : "Paid"}
                          </StatusLabel>
                        </TableCell>
                        <TableCell className={classes.statusItem}>
                          <StatusLabel
                            type={item.ready_to_publish ? "ready" : "draft"}
                          >
                            {item.ready_to_publish ? "Ready" : "Draft"}
                          </StatusLabel>
                        </TableCell>
                        <TableCell>{getArticleTypes(item)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </NormalCard>
        </Grid>
      </Grid>
      {openPublishMag && (
        <PublishMagazine
          open={openPublishMag}
          onClose={handleClosePublishMag}
          magData={magData}
          onSubmit={handleReloadMagazine}
        />
      )}
      {openCreateArt && (
        <CreateArticle
          open={openCreateArt}
          onClose={handleCloseCreatArt}
          magazine={magData}
          artID={selArtID}
          isPublished={magData.released}
          onSubmit={handleReloadMagazine}
        />
      )}
      {openEditMag && (
        <CreateMagazine
          open={openEditMag}
          onClose={handleCloseEditMag}
          onSubmit={handleReloadMagazine}
          isEdit={true}
          magData={magData}
          released={magData.released}
        />
      )}
      {openDeleteConfirm && (
        <ConfirmationDialog
          open={openDeleteConfirm}
          onClose={() => setOpenDeleteConfirm(false)}
          message="Are you sure you want to delete this Magazine?"
          onSubmit={onConfirmDeletion}
        />
      )}
      {isOpenQuizModal && (
        <CreateQuiz
          open={isOpenQuizModal}
          onClose={closeQuizModal}
          onSubmit={() => {}}
        />
      )}
    </Fragment>
  );
}
