import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Stack,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Checkbox,
  IconButton,
  Box,
} from "@mui/material";
import { ListingHeader, ContainerCard, HeaderButton } from "../../components";
import { magazine1, trophy, trash } from "../../assets";
import { makeStyles } from "@mui/styles";
import NoDataCard from "../../components/cards/NoDataCard";
import LoaderCard from "../../components/cards/LoaderCard";
import { api, urls } from "../../services";
import { useAuth, useToast } from "../../contexts";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  header: {
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
  // headItem: {
  //     fontWeight : 700
  //  }
}));
export const QuizDetails = (props) => {
  const classes = useStyles();
  const { logout } = useAuth();
  const { showToast } = useToast();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPage, setTotalPages] = useState(0);

  const [resultData, setResultData] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getQuizResults = () => {
    setLoading(true);
    api
      .get(urls.quizResult + id, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
        },
      })
      .then((res) => {
        setLoading(false);
        setResultData(res.data.data);
        setTotalPages(res.data.data.pagination.total);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getQuizResults();
  }, [page, rowsPerPage]);

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setResultData({
        ...resultData,
        winners_list: [...resultData?.winners_list, item],
      });
    } else {
      if (resultData?.winners_list.length === 1) {
        showToast("Please select atleast one winner", "error");
        return;
      }
      setResultData({
        ...resultData,
        winners_list: resultData?.winners_list.filter(
          (i) => i.user_id !== item.user_id
        ),
      });
    }
  };

  const handlePublish = () => {
    let params = [...resultData?.winners_list];
    api
      .post(urls.addQuizWinner + id, params)
      .then((res) => {
        showToast(res.data.message, "success");
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    console.log(resultData);
  }, [resultData]);

  return (
    <Fragment>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <ListingHeader
            title={
              totalPage > 0
                ? `Quiz Participants (${totalPage})`
                : "Quiz Participants"
            }
          >
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
                {resultData?.magazine_issue_name}
              </Typography>
              {resultData?.magazine_image && (
                <img
                  style={{ height: 70, objectFit: "cover", borderRadius: 4 }}
                  src={resultData?.magazine_image}
                  alt="mag"
                />
              )}
            </Stack>
          </ListingHeader>
        </Grid>
        {loading && (
          <Grid item xs>
            <LoaderCard />
          </Grid>
        )}
        {!loading && resultData && (
          <Grid item xs>
            <Grid container spacing={2}>
              <Grid item xs>
                <ContainerCard>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.header}>Name</TableCell>
                          <TableCell className={classes.header}>
                            Country
                          </TableCell>
                          <TableCell className={classes.header}>
                            State
                          </TableCell>
                          <TableCell className={classes.header}>
                            Score
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {resultData?.quiz_attended_list?.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell>
                              <Checkbox
                                onChange={(e) => handleCheckboxChange(e, item)}
                                checked={
                                  resultData?.winners_list?.find(
                                    (i) => i.user_id === item.user_id
                                  )
                                    ? true
                                    : false
                                }
                              />
                              {item.user_name}
                            </TableCell>
                            <TableCell>{item.country}</TableCell>
                            <TableCell>{item.state}</TableCell>
                            <TableCell>{`${item.correct_answers_count}/${item.questions_count}`}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {totalPage === 0 && <NoDataCard title="No Data Found" />}
                  <TablePagination
                    component="div"
                    count={totalPage}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 20, 30]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </ContainerCard>
              </Grid>
              {resultData?.winners_list?.length > 0 && (
                <Grid item xs={4}>
                  <ContainerCard>
                    <Stack spacing={2}>
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: 600,
                          p: 1,
                        }}
                      >
                        {resultData?.winners_list?.length > 0
                          ? `Selected Winners (${resultData?.winners_list?.length})`
                          : "Selected Winners"}
                      </Typography>
                      <Divider />
                      {resultData?.winners_list?.length > 0 && (
                        <Fragment>
                          <List disablePadding>
                            {resultData?.winners_list?.map((item, i) => (
                              <ListItem key={i}>
                                <Stack sx={{ width: "100%" }}>
                                  <Typography>{item.user_name}</Typography>
                                  <Typography>{`${item.state}, ${item.country}`}</Typography>
                                  <Typography>{`${item.phone_code} ${item.phone_number}`}</Typography>
                                </Stack>
                                <Stack>
                                  <img
                                    style={{ height: 20 }}
                                    src={trophy}
                                    alt="icon"
                                  />
                                  <Typography
                                    sx={{ fontSize: 14 }}
                                  >{`${item.correct_answers_count}/${item.questions_count}`}</Typography>
                                </Stack>
                                <IconButton
                                  sx={{ ml: 1 }}
                                  onClick={() => {
                                    if (resultData?.winners_list.length === 1) {
                                      showToast(
                                        "Please select atleast one winner",
                                        "error"
                                      );
                                      return;
                                    }
                                    setResultData({
                                      ...resultData,
                                      winners_list:
                                        resultData?.winners_list.filter(
                                          (i) => i !== item
                                        ),
                                    });
                                  }}
                                >
                                  <img
                                    style={{ height: 20 }}
                                    src={trash}
                                    alt="icon"
                                  />
                                </IconButton>
                              </ListItem>
                            ))}
                          </List>
                          <Divider />
                          <Box sx={{ width: 90 }}>
                            <HeaderButton
                              type="primary"
                              onClick={handlePublish}
                            >
                              Publish
                            </HeaderButton>
                          </Box>
                        </Fragment>
                      )}
                    </Stack>
                  </ContainerCard>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

QuizDetails.propTypes = {};
