import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import {
  ListingHeader,
  ContainerCard,
  MagazineTableItem,
  StatusLabel,
  PublishedLabel,
  ArticleTableItem,
  CopyArticle,
  CopyArticlebutton,
} from "../../components";
import { makeStyles } from "@mui/styles";
import { SelectMagazine } from "../popups/SelectMagazine";
import { DropdownListingHeader } from "../../components/headers/DropdownListingHeader";
import { CreateArticle } from "../CreateArticle";
import { api, urls } from "../../services";
import { useAuth, useToast } from "../../contexts";
import { debounce } from "lodash";
import NoDataCard from "../../components/cards/NoDataCard";
import LoaderCard from "../../components/cards/LoaderCard";

const useStyles = makeStyles((theme) => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 60,
    },
  },
  header: {
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
}));

const filterOptions = [
  { id: 0, label: "All" },
  { id: 1, label: "Draft" },
  { id: 2, label: "Ready" },
  { id: 3, label: "Published" },
];

export function Articles() {
  const classes = useStyles();
  const [openAddArticle, setOpenArticle] = useState(false);
  const [articles, setArticles] = useState([]);
  const { showToast } = useToast();
  const { logout } = useAuth();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPage, setTotalPages] = useState(0);
  const [clickedArt, setClickedArt] = useState(null);

  const [selFilter, setSelFilter] = useState(filterOptions[0]);
  const [searchInput, setSearchInput] = useState("");
  const [openSelectMag, setOpenSelectMag] = useState(false);
  const [magazines, setMagazines] = useState([]);
  const [selMag, setSelMag] = useState(null);
  const [openCreateArt, setOpenCreateArt] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClickArticle = (art) => {
    setOpenArticle(true);
    setClickedArt(art);
  };
  const handleCloseArticle = () => {
    setOpenArticle(false);
    setClickedArt(null);
  };

  const closeCreateArticle = () => {
    setOpenSelectMag(false);
  };

  const createArticleTapped = () => {
    setOpenSelectMag(true);
  };

  const handleSelectMag = (mag) => {
    setOpenSelectMag();
    setSelMag(mag);
    setOpenCreateArt(true);
  };

  const getArticles = () => {
    setIsLoading(true);
    api
      .get(urls.articles, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          filter_key: selFilter.id === 0 ? "" : selFilter.label.toLowerCase(),
          search_key: searchInput,
        },
      })
      .then((res) => {
        setArticles(res.data.data.articles);
        setTotalPages(res.data.data.pagination.total);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getMagazines = () => {
    api
      .get(urls.magazines, {
        params: {
          page: 1,
          limit: 100,
          filter_key: "",
          search_key: "",
        },
      })
      .then((res) => {
        setMagazines(res.data.data.magazines);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getArticles();
  }, [page, rowsPerPage, selFilter, searchInput]);

  useEffect(() => {
    getMagazines();
  }, []);

  const delayedSearch = useCallback(
    debounce((text) => {
      setSearchInput(text);
    }, 500),
    []
  );

  const handleOnSearch = (event) => {
    delayedSearch(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setSelFilter(event.target.value);
    setPage(0);
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <DropdownListingHeader
          title={totalPage > 0 ? `Articles (${totalPage})` : "Articles"}
          btnTitle="Create New Article"
          placeholder="Search Articles"
          onClickPrimary={createArticleTapped}
          filterOptions={filterOptions}
          selFilter={selFilter}
          onFilterChange={handleFilterChange}
          onSearchChange={handleOnSearch}
        />
      </Grid>
      {isLoading && (
        <Grid item>
          <LoaderCard />
        </Grid>
      )}
      {!isLoading && (
        <Grid item>
          <ContainerCard>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ fontSize: 16, fontWeight: 600 }}>
                    <TableCell className={classes.header}>Article</TableCell>
                    <TableCell className={classes.header}>Author</TableCell>
                    <TableCell className={classes.header}>Category</TableCell>
                    <TableCell className={classes.header}>Magazine</TableCell>
                    <TableCell className={classes.header}>Claps</TableCell>
                    <TableCell
                      align="center"
                      sx={{ pr: 2, fontSize: "16px", fontWeight: 600 }}
                    >
                      Status
                    </TableCell>
                    {/* <TableCell> </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {articles?.map((item, i) => {
                    return (
                      <TableRow
                        key={i}
                        sx={{ cursor: "pointer" }}
                        hover
                        onClick={() => handleOnClickArticle(item)}
                      >
                        <TableCell>
                          {
                            <ArticleTableItem
                              title={item.title}
                              image={item.feature_image}
                            />
                          }
                        </TableCell>
                        <TableCell>{item.author.name}</TableCell>
                        <TableCell>
                          {
                            item.article_categories[0].descriptions[0]
                              .category_name
                          }
                        </TableCell>
                        <TableCell>
                          <MagazineTableItem
                            noImage={true}
                            book={`${item.magazine.book_number} - ${item.magazine.year_number}`}
                            name={item.magazine.issue_name}
                          />
                        </TableCell>
                        <TableCell>{item.claps_count}</TableCell>
                        <TableCell className={classes.statusItem}>
                          {item.draft === true ? (
                            <StatusLabel type="draft">Draft</StatusLabel>
                          ) : (
                            <StatusLabel type="ready">Published</StatusLabel>
                          )}
                        </TableCell>
                        {/* <TableCell>
                          <CopyArticlebutton />
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {articles.length === 0 && !isLoading && (
              <NoDataCard title={"No Data Found"} />
            )}
            <TablePagination
              component="div"
              count={totalPage}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 20, 30]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </ContainerCard>
        </Grid>
      )}
      {openAddArticle && (
        <CreateArticle
          open={openAddArticle}
          onClose={handleCloseArticle}
          artID={clickedArt.id}
          isPublished={clickedArt.published}
          magazine={clickedArt.magazine}
          onSubmit={() => {
            handleCloseArticle();
            getArticles();
          }}
        />
      )}
      {openCreateArt && (
        <CreateArticle
          open={openCreateArt}
          onClose={() => setOpenCreateArt(false)}
          magazine={selMag}
          onSubmit={() => {
            setOpenCreateArt(false);
            getArticles();
          }}
        />
      )}
      {openSelectMag && (
        <SelectMagazine
          open={openSelectMag}
          onClose={closeCreateArticle}
          onSelect={handleSelectMag}
          magazines={magazines}
        />
      )}
    </Grid>
  );
}
