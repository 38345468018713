import React, { useState, useEffect } from "react";
import { Modal, Grid, Typography, Stack, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  NormalTextField,
  HeaderButton,
  DropdownTextField,
  DateTextField,
  CountryTextField,
} from "../../../components";
import { MuiTelInput } from "mui-tel-input";
import dayjs from "dayjs";
import { api, urls } from "../../../services";
import { useToast, useAuth } from "../../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 700,
    background: theme.palette.common.greyBg,
    boxShadow: 24,
    padding: theme.spacing(4),
    borderRadius: 6,
  },
  megazinePlanBox: {
    width: 300,
    border: "5px solid #ECECEC",
    padding: "20px",
    borderRadius: "4px",
  },
  discountBox: {
    background: "#EB363F12",
    color: "#EB363F",
    width: 170,
    fontSize: "12px",
    fontWeight: 500,
    padding: "10px",
    borderRadius: "4px",
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
  },
}));

export function AddUsers(props) {
  const classes = useStyles();
  const { open, onClose, onAddedUser } = props;
  const { showToast } = useToast();
  const { logout } = useAuth();
  const [formData, setFormData] = useState({
    name: null,
    email: null,
    phone_code: "+91",
    phone_number: null,
    plan: null,
    startDate: null,
    endDate: null,
    dob: null,
    country: null,
    state: null,
    religion: null,
    occupation: null,
    showError: true,
  });
  const [subscriptions, setSubscriptions] = useState([]);
  const [apiCalling, setApiCalling] = useState(false);

  const handleCancelClick = () => {
    onClose();
  };

  const handleSaveClick = () => {
    let params = {
      first_name: formData.name,
      email: formData.email,
      phone_code: formData.phone_code,
      phone_number: formData.phone_number,
      subscription_plan: formData.plan.id,
      plan_start_date: dayjs(formData.startDate).format("YYYY-MM-DD"),
      plan_end_date: dayjs(formData.endDate).format("YYYY-MM-DD"),
      dob: dayjs(formData.dob).format("DD-MMM-YYYY"),
      country: formData.country,
      state: formData.state,
      religion: formData.religion,
      occupation: formData.occupation,
    };
    setApiCalling(true);
    api
      .post(urls.addUser, params)
      .then((res) => {
        setApiCalling(false);
        showToast("User added successfully", "success");
        onAddedUser();
      })
      .catch((err) => {
        setApiCalling(false);
        showToast(err.response?.data?.message, "error");
      });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = (data) => {
    const {
      name,
      email,
      phone_code,
      phone_number,
      plan,
      startDate,
      endDate,
      dob,
      country,
      state,
      religion,
      occupation,
    } = data;

    return (
      !name?.trim() ||
      !email?.trim() ||
      !validateEmail(email) ||
      !phone_code?.trim() ||
      !phone_number?.trim() ||
      !plan ||
      !startDate ||
      !endDate ||
      (startDate && endDate && new Date(startDate) > new Date(endDate)) ||
      !dob ||
      !country?.trim() ||
      !state?.trim() ||
      !religion?.trim() ||
      !occupation?.trim()
    );
  };

  const handlePhoneInputChange = (value) => {
    let items = value.split(" ");
    let code = items[0];
    let phone = items.slice(1).join("");
    setFormData((prev) => {
      const updatedData = { ...prev, phone_code: code, phone_number: phone };
      return { ...updatedData, showError: validateForm(updatedData) };
    });
  };

  const handleNameInputChange = (e) => {
    setFormData((prev) => {
      const updatedData = { ...prev, name: e.target.value };
      return { ...updatedData, showError: validateForm(updatedData) };
    });
  };

  const handleEmailInputChange = (e) => {
    setFormData((prev) => {
      const updatedData = { ...prev, email: e.target.value };
      return { ...updatedData, showError: validateForm(updatedData) };
    });
  };

  const handleStartDateChange = (date) => {
    const startDate = new Date(date);

    // Calculate endDate by adding 365 days to startDate
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 365);

    setFormData((prev) => {
      const updatedData = { ...prev, startDate: startDate, endDate: endDate };
      return { ...updatedData, showError: validateForm(updatedData) };
    });
  };

  const handleEndDateChange = (date) => {
    setFormData((prev) => {
      const updatedData = { ...prev, endDate: date };
      return { ...updatedData, showError: validateForm(updatedData) };
    });
  };

  const handlePlanChange = (e, value) => {
    setFormData((prev) => {
      const updatedData = { ...prev, plan: value };
      return { ...updatedData, showError: validateForm(updatedData) };
    });
  };

  const handleDOBChange = (date) => {
    setFormData((prev) => {
      const updatedData = { ...prev, dob: date };
      return { ...updatedData, showError: validateForm(updatedData) };
    });
  };

  const handleCountryChange = (e) => {
    setFormData((prev) => {
      const updatedData = { ...prev, country: e.target.value };
      return { ...updatedData, showError: validateForm(updatedData) };
    });
  };

  const handleStateChange = (e) => {
    setFormData((prev) => {
      const updatedData = { ...prev, state: e.target.value };
      return { ...updatedData, showError: validateForm(updatedData) };
    });
  };

  const handleReligionChange = (e) => {
    setFormData((prev) => {
      const updatedData = { ...prev, religion: e.target.value };
      return { ...updatedData, showError: validateForm(updatedData) };
    });
  };

  const handleOccupationChange = (e) => {
    setFormData((prev) => {
      const updatedData = { ...prev, occupation: e.target.value };
      return { ...updatedData, showError: validateForm(updatedData) };
    });
  };

  useEffect(() => {
    setApiCalling(true);
    api
      .get(`${urls.subscriptionList}?page=1&limit=50`)
      .then((res) => {
        const subs = res.data.data.subscription_plans;
        if (subs.length > 0) {
          setSubscriptions(subs);
          setFormData((prev) => {
            const updatedData = {
              ...prev,
              plan: subs[0],
            };
            return { ...updatedData, showError: validateForm(updatedData) };
          });
        }

        setApiCalling(false);
      })

      .catch((err) => {
        setApiCalling(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  }, []);

  useEffect(() => {
    console.log("🚀 ~ AddUsers ~ formData:", formData);
  }, [formData]);

  return (
    <Modal open={open}>
      <Box className={classes.root}>
        <Typography className={classes.titleText}>Add User</Typography>
        <Grid container spacing={4} direction="column">
          <Grid item xs>
            <Stack spacing={4}>
              <Stack direction={"row"} spacing={2}>
                <NormalTextField
                  label="Name"
                  value={formData.name}
                  onChange={handleNameInputChange}
                />
                <NormalTextField
                  label="Email"
                  value={formData.email}
                  onChange={handleEmailInputChange}
                />
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <MuiTelInput
                  label="Phone Number"
                  sx={{ width: "100%" }}
                  defaultCountry="IN"
                  value={formData.phone_code + formData.phone_number}
                  onChange={handlePhoneInputChange}
                />
                <DateTextField
                  label="DOB"
                  onChange={handleDOBChange}
                  value={formData.dob === null ? null : dayjs(formData.dob)}
                  maxDate={dayjs(new Date())}
                />
              </Stack>
              <Stack spacing={2} direction="row">
                <DropdownTextField
                  label={"Subscription Plan"}
                  options={subscriptions}
                  values={formData.plan}
                  noClearIcon={true}
                  optionKey={"name"}
                  onChange={handlePlanChange}
                />
                <Stack spacing={2} direction="row" sx={{ width: "100%" }}>
                  <DateTextField
                    label="Start Date"
                    onChange={handleStartDateChange}
                    value={
                      formData.startDate === null
                        ? null
                        : dayjs(formData.startDate)
                    }
                  />
                  <DateTextField
                    label="End Date"
                    onChange={handleEndDateChange}
                    value={
                      formData.endDate === null ? null : dayjs(formData.endDate)
                    }
                    minDate={formData.startDate}
                    disabled
                  />
                </Stack>
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <CountryTextField
                  label="Country"
                  value={formData.country}
                  onChange={handleCountryChange}
                />
                <NormalTextField
                  label="State"
                  value={formData.state}
                  onChange={handleStateChange}
                />
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <NormalTextField
                  label="Religion"
                  value={formData.religion}
                  onChange={handleReligionChange}
                />
                <NormalTextField
                  label="Occupation"
                  value={formData.occupation}
                  onChange={handleOccupationChange}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <HeaderButton type="secondary" onClick={handleCancelClick}>
                  Cancel
                </HeaderButton>
                <HeaderButton
                  type="primary"
                  onClick={handleSaveClick}
                  disabled={formData.showError || apiCalling}
                >
                  Save
                </HeaderButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
