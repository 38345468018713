import React, { useState, Fragment } from "react";
import {
  Grid,
  Box,
  Typography,
  useTheme,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  Avatar,
  Divider,
  alpha,
  Paper,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  NormalTextField,
  NormalCard,
  ChipTextField,
  CoverUploader,
  ComponentCard,
  CategoryChipTextField,
  CategoryLabel,
  DropdownTextField,
  TextFieldWithDropDown,
  FileUploader,
  ContainerCard,
  ActionButton,
  BlockButton,
} from "../../components";
import { articleSettings, newArticle } from "../../utilities";
import { audio, audioWave, ad1, imagePlaceholder } from "../../assets";
import { SelectBlock } from "../../pages";
import { ceil, debounce } from "lodash";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 16,
  },
  checkBox: {
    "&.MuiCheckbox-root": {
      color: theme.palette.common.blueTxt,
      "&.Mui-checked": {
        color: theme.palette.common.blueTxt,
      },
      "&.Mui-disabled": {
        color: theme.palette.common.border,
      },
    },
  },
  title: {
    "&.MuiTypography-root": {
      color: "black",
      fontSize: 24,
      fontWeight: 700,
    },
  },
  author: {
    "&.MuiTypography-root": {
      color: "black",
      fontSize: 12,
      fontWeight: 500,
    },
  },
  audioAuthor: {
    "&.MuiTypography-root": {
      color: "white",
      fontSize: 16,
      fontWeight: 500,
    },
  },
  audioTime: {
    "&.MuiTypography-root": {
      color: "white",
      fontSize: 10,
      fontWeight: 500,
    },
  },
  readTime: {
    "&.MuiTypography-root": {
      color: theme.palette.common.lightGreyTxt,
      fontSize: 10,
      fontWeight: 600,
    },
  },

  book: {
    "&.MuiTypography-root": {
      color: theme.palette.theme.main,
      fontSize: 10,
      fontWeight: 500,
    },
  },
  subTitle: {
    "&.MuiTypography-root": {
      color: "black",
      fontSize: 18,
      fontWeight: 600,
    },
  },
  paragraph: {
    "&.MuiTypography-root": {
      color: "black",
      fontSize: 16,
      fontWeight: 500,
    },
  },
  highlighted: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    "&.MuiTypography-root": {
      color: theme.palette.common.redTxt,
      fontSize: 18,
      fontWeight: 700,
      background: alpha(theme.palette.common.redTxt, 0.1),
    },
  },
  preQuote: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    "&.MuiTypography-root": {
      color: theme.palette.common.greyTxt,
      fontSize: 12,
      fontWeight: 500,
      background: alpha(theme.palette.common.lightGreyTxt, 0.2),
    },
  },
  mainQuote: {
    "&.MuiTypography-root": {
      color: theme.palette.common.redTxt,
      fontSize: 18,
      fontWeight: 500,
    },
  },
  postQuote: {
    "&.MuiTypography-root": {
      color: "black",
      fontSize: 16,
      fontWeight: 500,
    },
  },
  thumbnail: {
    width: "100%",
    height: 200,
    borderRadius: 4,
    objectFit: "cover",
  },
  preview: {
    width: "auto  !important",
    paddingLeft: "8px ",
  },
  caption: {
    "&.MuiTypography-root": {
      color: theme.palette.common.lightGreyTxt,
      fontSize: 12,
      fontWeight: 500,
    },
  },
  adText: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 400,
    },
  },
  adTitleText: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 700,
    },
  },
  addBlockBox: {
    "&.MuiBox-root": {
      height: 100,
      borderRadius: theme.spacing(2),
      border: 2,
      borderColor: theme.palette.common.border,
      borderStyle: "dashed",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2),
      margin: theme.spacing(1),
    },
  },
}));

export function ArticleBuilder(props) {
  const {
    magazine,
    onDataChange,
    languages,
    categories,
    authors,
    data,
    coverImageUploading,
    isFreeArticle,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [openMenu, setOpenMenu] = useState(false);
  const [insertIndex, setInsertIndex] = useState(null);
  const [isFree, setIsFree] = useState(
    data?.is_free === 1 || isFreeArticle ? true : false
  );

  const handleAddClick = (e) => {
    setOpenMenu(true);
  };

  const handleSettingsChange = (index, value) => {
    const curr = { ...data };
    curr[articleSettings[index].key] = value;
    if (articleSettings[index].key === "is_free") {
      if (value) {
        const curr = { ...data };
        const updatedComponents = curr.components.map((component) => ({
          ...component,
          is_free: 1,
        }));
        curr.components = updatedComponents;
        onDataChange(updatedComponents);
      }
      setIsFree(value);
    }
    onDataChange(curr);
  };

  const handleKeywordsChange = (e, n) => {
    const curr = { ...data };
    curr.keywords = n;
    curr.errors.keywords = false;
    onDataChange(curr);
  };

  const handleCategoriesChange = (e, n) => {
    const curr = { ...data };
    curr.article_categories = n.map((x) => x.id);
    curr.components[0].category = n;
    curr.errors.categories = false;
    onDataChange(curr);
  };

  const handleAuthorChange = (e, n) => {
    const curr = { ...data };
    curr.author_id = n ? n.id : "";
    curr.author_name = n ? n.author_name : "";
    curr.author_image = n ? n.author_image : "";
    curr.components[2].author.name = n ? n.author_name : "";
    curr.components[2].author.image = n ? n.author_image : "";
    curr.components[2].author.book_number = magazine?.book_number ?? "";
    curr.components[2].author.year_number = magazine?.year_number ?? "";
    curr.errors.author = false;
    onDataChange(curr);
  };

  const handleDividerChange = (index, value) => {
    const curr = { ...data };
    curr.components[index].full_width = value === true ? 1 : 0;
    onDataChange(curr);
  };

  const handleIsFreeChange = (index, value) => {
    const curr = { ...data };
    curr.components[index].is_free = value === true ? 1 : 0;
    onDataChange(curr);
  };

  const handleTextWithDropTextChange = (c, v, i) => {
    if (c.type === 2) {
      const curr = { ...data };
      curr.title = v;
      curr.components[i].text = v;
      curr.errors.title = false;
      onDataChange(curr);
    } else {
      const curr = { ...data };
      curr.components[i].text = v;
      onDataChange(curr);
    }
  };

  const handleTextWithDropLangChange = (c, v, i) => {
    if (c.type === 2) {
      const curr = { ...data };
      curr.title_lang = v.id;
      curr.components[i].text_lang = v.id;
      onDataChange(curr);
    } else {
      const curr = { ...data };
      curr.components[i].text_lang = v.id;
      onDataChange(curr);
    }
  };

  const handleQuoteTextChange = (k, v, i) => {
    const curr = { ...data };
    curr.components[i][k] = v;
    onDataChange(curr);
  };

  const handleQuoteLangChange = (k, v, i) => {
    const curr = { ...data };
    curr.components[i][k] = v.id;
    onDataChange(curr);
  };

  const handleMoveUp = (i) => {
    const curr = { ...data };
    curr.components.splice(i - 1, 0, curr.components.splice(i, 1)[0]);
    onDataChange(curr);
  };

  const handleMoveDown = (i) => {
    const curr = { ...data };
    curr.components.splice(i + 1, 0, curr.components.splice(i, 1)[0]);
    onDataChange(curr);
  };

  const handleDuplicate = (i) => {
    const curr = { ...data };
    const item = { ...curr.components[i] };
    curr.components.splice(i + 1, 0, item);
    onDataChange(curr);
  };

  const handleDelete = (i) => {
    const curr = { ...data };
    curr.components.splice(i, 1);
    onDataChange(curr);
  };

  const handleAudioAuthorChange = (v, i) => {
    const curr = { ...data };
    const author = { ...curr.components[i].author };
    author.name = v ? v.author_name : "";
    author.image = v ? v.author_image : "";
    curr.components[i].author = author;
    onDataChange(curr);
  };

  const handleFileURLChange = (v, i) => {
    const curr = { ...data };
    curr.components[i].url = v;
    onDataChange(curr);
  };

  const handleThumbnailURLChange = (v, i) => {
    const curr = { ...data };
    curr.components[i].thumbnail = v;
    onDataChange(curr);
  };

  const handleLinkChange = (i, t, u) => {
    const curr = { ...data };
    curr.components[i].url = u;
    curr.components[i].caption_text = t;
    onDataChange(curr);
  };

  const handleCloseOpenMenu = () => {
    setOpenMenu(false);
    setInsertIndex(null);
  };

  const handleSelectMenu = (m) => {
    const item = { ...m };
    delete item.isSingle;
    item.is_free = isFree ? 1 : 0;
    const curr = { ...data };
    if (insertIndex) {
      curr.components.splice(insertIndex, 0, item);
    } else {
      curr.components.push(item);
    }
    onDataChange(curr);
    handleCloseOpenMenu();
  };

  const handleInsertClick = (i) => {
    setInsertIndex(i);
    handleAddClick();
  };

  const handleUrlChange = (url) => {
    const curr = { ...data };
    curr.feature_image = url;
    curr.errors.feature_image = false;
    onDataChange(curr);
  };

  const handleReadTimeChange = (time) => {
    const curr = { ...data };
    curr.components[2].author.read_time = parseInt(time);
    curr.errors.read_time = false;
    onDataChange(curr);
  };

  const insertBlock = (i) => {
    return (
      <Grid container spacing={2} alignItems="center" paddingBottom={2}>
        <Grid item xs>
          <Divider />
        </Grid>
        <Grid item>
          <BlockButton onClick={() => handleInsertClick(i)}>
            Insert Block
          </BlockButton>
        </Grid>
        <Grid item xs>
          <Divider />
        </Grid>
      </Grid>
    );
  };

  const getArticleComponent = (c, i) => {
    if (c.type === 1) {
      return (
        <CategoryChipTextField
          label={`Select ${c.type_name}`}
          options={categories}
          values={data.components[0].category}
          onChange={handleCategoriesChange}
          error={data.errors.categories}
        />
      );
    } else if (c.type === 2 || c.type === 3 || c.type === 4 || c.type === 10) {
      return (
        <TextFieldWithDropDown
          key={i}
          label={c.type_name}
          multiline={true}
          textValue={c.text}
          onTextChange={(e) =>
            handleTextWithDropTextChange(c, e.target.value, i)
          }
          dropOptions={languages}
          dropValue={languages.find((x) => x.id === c.text_lang)}
          dropKey={"short_code"}
          onDropChange={(e) =>
            handleTextWithDropLangChange(c, e.target.value, i)
          }
          textError={c.type === 2 && data.errors.title}
        />
      );
    } else if (c.type === 5) {
      return (
        <DropdownTextField
          key={i}
          label={`Select ${c.type_name}`}
          options={authors}
          optionKey={"author_name"}
          onChange={handleAuthorChange}
          error={data.errors.author}
          values={
            authors.length > 0 && authors.find((x) => x.id === data.author_id)
          }
        />
      );
    } else if (c.type === 0) {
      return (
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkBox}
              onChange={(e, value) => handleDividerChange(i, value)}
              checked={Boolean(data.components[i].full_width)}
            />
          }
          label="Full Width"
        />
      );
    } else if (c.type === 9) {
      return (
        <Stack spacing={2}>
          <TextFieldWithDropDown
            label={"Pre Quote"}
            multiline={true}
            textValue={c.pre_text}
            onTextChange={(e) =>
              handleQuoteTextChange("pre_text", e.target.value, i)
            }
            dropOptions={languages}
            dropValue={languages.find((x) => x.id === c.pre_text_lang)}
            dropKey={"short_code"}
            onDropChange={(e) =>
              handleQuoteLangChange("pre_text_lang", e.target.value, i)
            }
          />
          <TextFieldWithDropDown
            label={"Main Quote"}
            multiline={true}
            textValue={c.main_text}
            onTextChange={(e) =>
              handleQuoteTextChange("main_text", e.target.value, i)
            }
            dropOptions={languages}
            dropValue={languages.find((x) => x.id === c.main_text_lang)}
            dropKey={"short_code"}
            onDropChange={(e) =>
              handleQuoteLangChange("main_text_lang", e.target.value, i)
            }
          />
          <TextFieldWithDropDown
            label={"Post Quote"}
            multiline={true}
            textValue={c.post_text}
            onTextChange={(e) =>
              handleQuoteTextChange("post_text", e.target.value, i)
            }
            dropOptions={languages}
            dropValue={languages.find((x) => x.id === c.post_text_lang)}
            dropKey={"short_code"}
            onDropChange={(e) =>
              handleQuoteLangChange("post_text_lang", e.target.value, i)
            }
          />
        </Stack>
      );
    } else if (c.type === 6) {
      return (
        <Stack spacing={2}>
          <DropdownTextField
            key={i}
            label="Voice of"
            options={authors}
            optionKey={"author_name"}
            onChange={(e, v) => handleAudioAuthorChange(v, i)}
            values={authors.find((x) => x.author_name === c.author.name)}
          />
          <FileUploader
            label={`${c.type_name} File`}
            fileType="audio"
            disabled={true}
            onChangeURL={(v) => handleFileURLChange(v, i)}
            url={c.url}
          />
        </Stack>
      );
    } else if (c.type === 7) {
      return (
        <Stack spacing={2}>
          <FileUploader
            label={`${c.type_name} File`}
            fileType="image"
            disabled={true}
            onChangeURL={(v) => handleFileURLChange(v, i)}
            url={c.url}
          />
          <TextFieldWithDropDown
            label={`${c.type_name} Caption`}
            multiline={true}
            textValue={c.caption_text}
            onTextChange={(e) =>
              handleQuoteTextChange("caption_text", e.target.value, i)
            }
            dropOptions={languages}
            dropValue={languages.find((x) => x.id === c.caption_lang)}
            dropKey={"short_code"}
            onDropChange={(e) =>
              handleQuoteLangChange("caption_lang", e.target.value, i)
            }
          />
        </Stack>
      );
    } else if (c.type === 8) {
      return (
        <Stack spacing={2}>
          <FileUploader
            label={`${c.type_name} File`}
            fileType="video"
            disabled={true}
            onChangeURL={(v) => handleFileURLChange(v, i)}
            url={c.url}
          />
          <FileUploader
            label={`Thumbnail File`}
            fileType="image"
            disabled={true}
            onChangeURL={(v) => handleThumbnailURLChange(v, i)}
            url={c.thumbnail}
          />
          <TextFieldWithDropDown
            label={`${c.type_name} Caption`}
            multiline={true}
            textValue={c.caption_text}
            onTextChange={(e) =>
              handleQuoteTextChange("caption_text", e.target.value, i)
            }
            dropOptions={languages}
            dropValue={languages.find((x) => x.id === c.caption_lang)}
            dropKey={"short_code"}
            onDropChange={(e) =>
              handleQuoteLangChange("caption_lang", e.target.value, i)
            }
          />
        </Stack>
      );
    } else if (c.type === 12) {
      return (
        <Stack spacing={2}>
          <NormalTextField
            label={`${c.type_name} Text`}
            multiline={true}
            onChange={(e) => handleLinkChange(i, e.target.value, c.url)}
            value={c.caption_text}
          />
          <NormalTextField
            label="URL"
            onChange={(e) =>
              handleLinkChange(i, c.caption_text, e.target.value)
            }
            value={c.url}
          />
        </Stack>
      );
    } else if (c.type === 11) {
      return (
        <Typography className={classes.adText}>
          Ads will be shown randomly
        </Typography>
      );
    } else {
      return (
        <NormalTextField
          key={i}
          label={c.type_name}
          multiline={true}
          // onChange={(e) => handleContentChange(i, e.target.value)}
        />
      );
    }
  };

  const getPreviewComponent = (c, i) => {
    if (c.type === 1) {
      return (
        <Stack direction="row" spacing={1} mt={2}>
          {c.category.map((cat, i) => {
            return (
              <CategoryLabel key={i} color={cat.category_colour}>
                {cat.descriptions[0].category_name}
              </CategoryLabel>
            );
          })}
        </Stack>
      );
    } else if (c.type === 2) {
      return (
        <Typography whiteSpace="pre-line" className={classes.title}>
          {c.text}
        </Typography>
      );
    } else if (c.type === 5) {
      return c.author.name ? (
        <Stack direction="row" spacing={1}>
          <Avatar alt="img" src={c.author.image} />
          <Stack>
            <Typography className={classes.author}>{c.author.name}</Typography>
            <Stack direction="row" spacing={1}>
              <Typography
                className={classes.readTime}
              >{`${c.author.read_time} min read`}</Typography>
              {!isFreeArticle && (
                <Typography
                  className={classes.book}
                >{`പുസ്തകം ${c.author.book_number} - ${c.author.year_number}`}</Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      ) : null;
    } else if (c.type === 0) {
      return <Divider />;
    } else if (c.type === 3) {
      return (
        <Typography whiteSpace="pre-line" className={classes.subTitle}>
          {c.text}
        </Typography>
      );
    } else if (c.type === 4) {
      return (
        <Typography whiteSpace="pre-line" className={classes.paragraph}>
          {c.text}
        </Typography>
      );
    } else if (c.type === 10) {
      return c.text !== "" ? (
        <Typography whiteSpace="pre-line" className={classes.highlighted}>
          {c.text}
        </Typography>
      ) : null;
    } else if (c.type === 9) {
      return (
        <Stack
          spacing={1}
          sx={{
            overflowWrap: "break-word",
            width: "100%",
            maxWidth: "100%",
          }}
        >
          {c.pre_text !== "" && (
            <Box>
              <Typography whiteSpace="pre-line" className={classes.preQuote}>
                {c.pre_text}
              </Typography>
            </Box>
          )}
          <Typography whiteSpace="pre-line" className={classes.mainQuote}>
            {c.main_text}
          </Typography>
          <Typography whiteSpace="pre-line" className={classes.postQuote}>
            {c.post_text}
          </Typography>
        </Stack>
      );
    } else if (c.type === 6) {
      return c.author.name !== "" && c.url !== "" ? (
        <Paper sx={{ background: "black", padding: 2, borderRadius: 2 }}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={1}>
              {c.author.image && <Avatar alt="img" src={c.author.image} />}
              <Stack>
                {c.author.name && (
                  <Fragment>
                    <Typography className={classes.audioAuthor}>
                      {c.author.name}
                    </Typography>
                    <Typography className={classes.audioTime}>3:40</Typography>
                  </Fragment>
                )}
              </Stack>
            </Stack>
            <a href={c.url} target="_blank">
              <Stack direction="row" spacing={1}>
                <img alt="img" src={audio} style={{ height: 30, width: 30 }} />
                <img
                  alt="img"
                  src={audioWave}
                  style={{ height: 30, width: "80%", objectFit: "cover" }}
                />
              </Stack>
            </a>
          </Stack>
        </Paper>
      ) : null;
    } else if (c.type === 7) {
      return (
        <Stack spacing={1} alignItems="center">
          {c.url && (
            <a className={classes.thumbnail} href={c.url} target="_blank">
              <img className={classes.thumbnail} alt="img" src={c.url} />
            </a>
          )}
          <Typography className={classes.caption}>{c.caption_text}</Typography>
        </Stack>
      );
    } else if (c.type === 8) {
      return (
        <Stack spacing={1} alignItems="center">
          {c.url && (
            <a className={classes.thumbnail} href={c.url} target="_blank">
              <Stack alignItems={"center"}>
                {/* <ReactPlayer
                  width={"100%"}
                  height={"100%"}
                  url={c.url}
                  controls={false}
                /> */}
                <img
                  className={classes.thumbnail}
                  alt="img"
                  src={c.thumbnail ? c.thumbnail : imagePlaceholder}
                />
                <PlayArrowIcon
                  color="white"
                  sx={{ mt: "-120px", width: 50, height: 50, zIndex: 1500 }}
                />
              </Stack>
            </a>
          )}
          <Typography className={classes.caption}>{c.caption_text}</Typography>
        </Stack>
      );
    } else if (c.type === 12) {
      return c.caption_text !== "" && c.url !== "" ? (
        <ContainerCard>
          <Stack direction="row" spacing={1}>
            <Stack sx={{ width: "100%" }}>
              <Typography className={classes.paragraph}>
                {c.caption_text}
              </Typography>
              <Typography className={classes.caption}>{c.url}</Typography>
            </Stack>
            <Box sx={{ height: 30, width: 70 }}>
              <ActionButton>Open</ActionButton>
            </Box>
          </Stack>
        </ContainerCard>
      ) : null;
    } else if (c.type === 11) {
      return (
        <Stack spacing={1}>
          <Box
            sx={{
              width: "100%",
              height: 100,
              border: 1,
              borderRadius: 2,
              borderColor: theme.palette.common.border,
              borderStyle: "dashed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography className={classes.adTitleText}>
              Random Advertisement
            </Typography>
          </Box>
          {/* <img className={classes.thumbnail} alt="img" src={ad1} /> */}
          {/* <Typography className={classes.paragraph}>Ad Post text</Typography> */}
        </Stack>
      );
    }
  };

  return (
    <Fragment>
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2} direction={"row"}>
          <Grid item md={3} xl={2} sx={12} direction={"column"}>
            <Grid container direction="column" spacing={2}>
              <Grid item key={data.feature_image}>
                <NormalCard title="Feature Image">
                  <Box className={classes.imageContainer}>
                    <CoverUploader
                      fileURL={data.feature_image}
                      onChangeURL={handleUrlChange}
                      uploadingStatus={coverImageUploading}
                      aspectRatio={2.5 / 3.3}
                    />
                  </Box>
                  {data.errors.feature_image && (
                    <FormHelperText
                      error
                      sx={{ width: "100%", textAlign: "center" }}
                    >
                      Feature image is required
                    </FormHelperText>
                  )}
                </NormalCard>
              </Grid>
              <Grid item>
                <NormalCard title="Article Settings">
                  <FormGroup>
                    {articleSettings.map((s, i) => {
                      return (
                        <FormControlLabel
                          key={i}
                          control={
                            <Checkbox
                              className={classes.checkBox}
                              checked={data[s.key]}
                              onChange={(e) =>
                                handleSettingsChange(i, e.target.checked)
                              }
                            />
                          }
                          label={s.title}
                          disabled={i === 0 && isFreeArticle}
                        />
                      );
                    })}
                  </FormGroup>
                </NormalCard>
              </Grid>
              <Grid
                item
                sx={{
                  overflowWrap: "break-word",
                  width: "100%",
                  maxWidth: "100%",
                }}
              >
                <NormalCard title="Keywords">
                  <ChipTextField
                    options={[]}
                    values={data.keywords}
                    onChange={handleKeywordsChange}
                    error={data.errors.keywords}
                    helperText={data.errors.keywords && "Keywords are required"}
                  />
                </NormalCard>
              </Grid>
              <Grid item>
                <NormalCard title="Read Time">
                  <NormalTextField
                    type="number"
                    suffix="min"
                    value={data.components[2].author.read_time}
                    onChange={(e) => handleReadTimeChange(e.target.value)}
                    error={data.errors.read_time}
                    helperText={
                      data.errors.read_time && "Read time is required"
                    }
                  />
                </NormalCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xl={7} sx={12}>
            <Grid
              container
              direction="column"
              spacing={2}
              className={classes.preview}
            >
              {data.components.map((c, i) => {
                return (
                  <Grid item xs={12} key={i}>
                    {![0, 1, 2, 3].includes(i) && insertBlock(i)}
                    <ComponentCard
                      title={c.type_name}
                      disableAll={[0, 1, 2, 3].includes(i)}
                      isFree={[0, 1, 2, 3].includes(i) || c.is_free || isFree}
                      isFreeReadOnly={isFree}
                      onIsFreeChange={(v) => handleIsFreeChange(i, v)}
                      disableUp={i === 4}
                      disableDown={i === data.components.length - 1}
                      onMoveUp={() => handleMoveUp(i)}
                      onMoveDown={() => handleMoveDown(i)}
                      onDuplicate={() => handleDuplicate(i)}
                      onDelete={() => handleDelete(i)}
                    >
                      {getArticleComponent(c, i)}
                    </ComponentCard>
                  </Grid>
                );
              })}
              <Grid item>
                <ContainerCard>
                  <Box className={classes.addBlockBox}>
                    <BlockButton onClick={handleAddClick}>
                      Add Block
                    </BlockButton>
                  </Box>
                </ContainerCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3} xl={3} sx={12}>
            <NormalCard title="Preview">
              <Grid container direction="column" spacing={2}>
                <Fragment>
                  {data.components.map((c, i) => {
                    return (
                      <Grid
                        item
                        key={i}
                        sx={{
                          overflowWrap: "break-word",
                          width: "100%",
                          maxWidth: "100%",
                        }}
                      >
                        {getPreviewComponent(c, i)}
                      </Grid>
                    );
                  })}
                </Fragment>
              </Grid>
            </NormalCard>
          </Grid>
        </Grid>
      </Box>
      <SelectBlock
        open={openMenu}
        onClose={handleCloseOpenMenu}
        onSelect={handleSelectMenu}
      />
    </Fragment>
  );
}

ArticleBuilder.propTypes = {
  magazine: PropTypes.object,
  onDataChange: PropTypes.func,
  languages: PropTypes.array,
  categories: PropTypes.array,
  authors: PropTypes.array,
  isFreeArticle: PropTypes.bool,
};
