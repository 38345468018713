import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { logo, SideMenuIcon } from "../../assets";
import { sideMenuOptions } from "../../utilities";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts";
import { LogoutDialog } from "../../components";

const useStyles = makeStyles((theme) => ({
  paper: {
    "&.MuiPaper-root": {
      minHeight: "96vh",
      borderRadius: 0,
      marginTop: theme.spacing(1),
    },
  },
  logoContainer: {
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoImage: {
    width: 220,
    height: 70,
    cursor: "pointer",
  },
  listside: {
    minWidth: "40px !important",
    padding: "0px",
  },
}));

export function SideMenu() {
  const classes = useStyles();
  const theme = useTheme();
  const [options, setOptions] = useState(sideMenuOptions);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const location = useLocation();
  const [openLogout, setOpenLogout] = useState(false);

  const handleOptionClick = (i) => {
    if (options[i].type === 12) {
      // logout();
      setOpenLogout(true);
    } else {
      setOptions((prev) => {
        const curr = [...prev];
        curr.map((c) => (c.isSelected = false));
        curr[i].isSelected = true;
        return curr;
      });
      navigate(options[i].link);
    }
  };

  useEffect(() => {
    if (location.pathname !== "/") {
      const index = sideMenuOptions.findIndex(
        (x) => x.link === location.pathname
      );
      if (index >= 0) {
        setOptions((prev) => {
          const curr = [...prev];
          curr.map((c) => (c.isSelected = false));
          curr[index].isSelected = true;
          return curr;
        });
      } else {
        setOptions((prev) => {
          const curr = [...prev];
          curr.map((c) => (c.isSelected = false));
          curr[0].isSelected = true;
          return curr;
        });
      }
    }
  }, [location.pathname]);

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={1} direction="column">
        <Grid item xs>
          <Box className={classes.logoContainer}>
            <img
              className={classes.logoImage}
              alt="logo"
              src={logo}
              onClick={() => handleOptionClick(0)}
            />
          </Box>
          <Divider />
        </Grid>
        <Grid item xs>
          <List>
            {options.map((m, i) => {
              return (
                <ListItem disablePadding key={i}>
                  <ListItemButton onClick={() => handleOptionClick(i)}>
                    <ListItemIcon sx={{ pl: 2 }} className={classes.listside}>
                      <SideMenuIcon
                        name={m.icon}
                        size={20}
                        color={m.isSelected && theme.palette.theme.main}
                        sx={{ padding: "0" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        color: m.isSelected && theme.palette.theme.main,
                      }}
                    >
                      {m.title}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
      {openLogout && (
        <LogoutDialog open={openLogout} onClose={() => setOpenLogout(false)} />
      )}
    </Paper>
  );
}
