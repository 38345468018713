import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { magazine1 } from "../../assets";
import PropTypes from "prop-types";
import { MagazineDetails } from "../../pages";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  coverImage: {
    width: 40,
    height: 55,
    borderRadius: 4,
    objectFit: "cover",
  },
  bookLabel: {
    "&.MuiTypography-root": {
      color: theme.palette.theme.main,
      fontSize: 14,
      fontWeight: 500,
    },
  },
  nameLabel: {
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 600,
    },
  },
}));

export function MagazineTableItem(props) {
  const classes = useStyles();
  const { noImage, cover, book, name } = props;

  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center">
        {noImage === true ? null : (
          <img alt="mag" src={cover} className={classes.coverImage} />
        )}
        {/* <img alt="mag" src={magazine1} className={classes.coverImage} /> */}
        <Stack>
          <Typography
            className={classes.bookLabel}
          >{`പുസ്തകം ${book}`}</Typography>
          <Typography className={classes.nameLabel}>{name}</Typography>
          {/* <Typography className={classes.bookLabel}>പുസ്തകം 17 - 42</Typography>
          <Typography className={classes.nameLabel}>
          2023 SEP 01 - 30
          </Typography> */}
        </Stack>
      </Stack>
    </Box>
  );
}

MagazineTableItem.propTypes = {
  noImage: PropTypes.bool,
  cover: PropTypes.string,
  book: PropTypes.string,
  name: PropTypes.string,
};
