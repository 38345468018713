import {
  Paper,
  Divider,
  Box,
  Typography,
  Stack,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RedLinkButton } from "../../components";
import PropTypes from "prop-types";
import viewall from "../../assets/images/viewall.svg";
import { SideMenuIcon } from "../../assets";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    // paddingLeft: theme.spacing(3),
    "&.MuiPaper-root": {
      // borderRadius: 0,
    },
  },
  titleBar: {
    height: 40,
  },
  titleText: {
    "&.MuiTypography-root": { fontSize: 20, fontWeight: 600 },
  },
  divider: {
    "&.MuiDivider-root": {
      marginTop: theme.spacing(1),
      // marginBottom: theme.spacing(2),
    },
  },
  viewicon: {
    paddingLeft: "10px",
  },
}));

export function NormalCard(props) {
  const classes = useStyles();
  const { children, title, showViewAll, noDivider, onViewAllClick } = props;
  const theme = useTheme();

  return (
    <Paper className={classes.root}>
      <Box className={classes.titleBar}>
        <Stack
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography className={classes.titleText}>{title}</Typography>
          {showViewAll === true && (
            <RedLinkButton onClick={onViewAllClick}>
              View All
              {/* <img src={viewall} className={classes.viewicon} /> */}
              <SideMenuIcon
                name={"ri-arrow-right-line"}
                size={20}
                color={theme.palette.theme.main}
                sx={{ padding: "0" }}
              />
            </RedLinkButton>
          )}
        </Stack>
      </Box>
      {noDivider === true ? null : <Divider className={classes.divider} />}
      {children}
    </Paper>
  );
}

NormalCard.propTypes = {
  title: PropTypes.string,
  showViewAll: PropTypes.bool,
  noDivider: PropTypes.bool,
  onViewAllClick: PropTypes.func,
};
