import React, { useEffect, useState } from "react";
import { Modal, Grid, Typography, Stack, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HeaderButton, TextFieldWithDropDown } from "../../../components";
import { api, urls } from "../../../services";
import { useToast } from "../../../contexts";
// import { languages } from "../../../utilities";
import stc from "string-to-color";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    background: theme.palette.common.white,
    boxShadow: 24,
    padding: theme.spacing(4),
    borderRadius: 6,
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
  },
}));

export function AddQuote(props) {
  const classes = useStyles();
  const { open, onClose, onSubmit, languages, isEdit, selQuote } = props;
  const { showToast } = useToast();
  const [formData, setFormData] = useState({
    title_name: selQuote?.title_name ?? "",
    title_lang: selQuote?.title_lang ?? 2,
    pre_quote: selQuote?.pre_quote ?? "",
    pre_quote_lang: selQuote?.pre_quote_lang ?? 2,
    main_quote: selQuote?.main_quote ?? "",
    main_quote_lang: selQuote?.main_quote_lang ?? 2,
    post_quote: selQuote?.post_quote ?? "",
    post_quote_lang: selQuote?.post_quote_lang ?? 2,
    errors: {
      title_name: false,
      pre_quote: false,
      main_quote: false,
      post_quote: false,
    },
  });
  const [loading, setLoading] = useState(false);

  const handleQuoteTextChange = (k, v) => {
    setFormData((prev) => {
      let curr = { ...prev };
      curr[k] = v;
      return curr;
    });
  };

  const handleQuoteLangChange = (k, v) => {
    setFormData((prev) => {
      let curr = { ...prev };
      curr[k] = v.id;
      return curr;
    });
  };

  const handleSaveClick = () => {
    if (!formData.title_name.trim()) {
      setFormData((prev) => {
        let curr = { ...prev };
        curr.errors.title_name = true;
        return curr;
      });
    }
    if (!formData.main_quote.trim()) {
      setFormData((prev) => {
        let curr = { ...prev };
        curr.errors.main_quote = true;
        return curr;
      });
    }

    if (formData.title_name.trim() && formData.main_quote.trim()) {
      let params = {
        title_name: formData.title_name,
        title_lang: formData.title_lang,
        pre_quote: formData.pre_quote,
        pre_quote_lang: formData.pre_quote_lang,
        main_quote: formData.main_quote,
        main_quote_lang: formData.main_quote_lang,
        post_quote: formData.post_quote,
        post_quote_lang: formData.post_quote_lang,
      };
      setLoading(true);
      if (isEdit) {
        api
          .put(urls.editQuote + selQuote.id, params)
          .then((res) => {
            showToast(res.data.message, "success");
            onSubmit();
            setLoading(false);
          })
          .catch((err) => {
            showToast(err.response?.data?.message, "error");
            setLoading(false);
          });
      } else {
        api
          .post(urls.createQuote, params)
          .then((res) => {
            showToast(res.data.message, "success");
            onSubmit();
            setLoading(false);
          })
          .catch((err) => {
            showToast(err.response?.data?.message, "error");
            setLoading(false);
          });
      }
    }
  };

  const handleCancelClick = () => {
    onClose();
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.root}>
        <Typography className={classes.titleText}>
          {isEdit ? "Edit Quote" : "Add Quote"}
        </Typography>
        <Grid container spacing={4} direction="column">
          <Grid item xs>
            <Stack spacing={4}>
              <Grid item xs>
                <Stack spacing={2}>
                  <TextFieldWithDropDown
                    label={"Quote Title"}
                    multiline={true}
                    textValue={formData.title_name}
                    onTextChange={(e) => {
                      handleQuoteTextChange("title_name", e.target.value);
                      setFormData((prev) => {
                        let curr = { ...prev };
                        curr.errors.title_name = false;
                        return curr;
                      });
                    }}
                    dropOptions={languages}
                    dropValue={languages.find(
                      (x) => x.id === formData.title_lang
                    )}
                    dropKey={"short_code"}
                    onDropChange={(e) =>
                      handleQuoteLangChange("title_lang", e.target.value)
                    }
                    textError={formData.errors.title}
                    textHelperText={
                      formData.errors.title && "Enter quote title"
                    }
                  />
                  <TextFieldWithDropDown
                    label={"Pre Quote"}
                    multiline={true}
                    textValue={formData.pre_quote}
                    onTextChange={(e) =>
                      handleQuoteTextChange("pre_quote", e.target.value)
                    }
                    dropOptions={languages}
                    dropValue={languages.find(
                      (x) => x.id === formData.pre_quote_lang
                    )}
                    dropKey={"short_code"}
                    onDropChange={(e) =>
                      handleQuoteLangChange("pre_quote_lang", e.target.value)
                    }
                  />
                  <TextFieldWithDropDown
                    label={"Main Quote"}
                    multiline={true}
                    textValue={formData.main_quote}
                    onTextChange={(e) => {
                      handleQuoteTextChange("main_quote", e.target.value);
                      setFormData((prev) => {
                        let curr = { ...prev };
                        curr.errors.main_quote = false;
                        return curr;
                      });
                    }}
                    dropOptions={languages}
                    dropValue={languages.find(
                      (x) => x.id === formData.main_quote_lang
                    )}
                    dropKey={"short_code"}
                    onDropChange={(e) =>
                      handleQuoteLangChange("main_quote_lang", e.target.value)
                    }
                    textError={formData.errors.main_quote}
                    textHelperText={
                      formData.errors.main_quote && "Enter main quote"
                    }
                  />
                  <TextFieldWithDropDown
                    label={"Post Quote"}
                    multiline={true}
                    textValue={formData.post_quote}
                    onTextChange={(e) =>
                      handleQuoteTextChange("post_quote", e.target.value)
                    }
                    dropOptions={languages}
                    dropValue={languages.find(
                      (x) => x.id === formData.post_quote_lang
                    )}
                    dropKey={"short_code"}
                    onDropChange={(e) =>
                      handleQuoteLangChange("post_quote_lang", e.target.value)
                    }
                  />
                </Stack>
              </Grid>
              <Stack direction="row" spacing={2}>
                <HeaderButton type="secondary" onClick={handleCancelClick}>
                  Cancel
                </HeaderButton>
                <HeaderButton
                  type="primary"
                  onClick={handleSaveClick}
                  disabled={loading}
                >
                  {isEdit ? "Update" : "Save"}
                </HeaderButton>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

AddQuote.propsTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  languages: PropTypes.array,
  isEdit: PropTypes.bool,
  selQuote: PropTypes.object,
};
