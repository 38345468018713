import { Paper, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HeaderButton, SearchTextField } from "../../components";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  paper: {
    "&.MuiPaper-root": {
      borderRadius: 0,
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
  },
}));

export function UsersHeader(props) {
  const classes = useStyles();
  const {
    title,
    btnTitle,
    placeholder,
    searchInput,
    onSearchChange,
    onClickPrimary,
    onClickSecondary,
  } = props;

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography className={classes.titleText}>{title}</Typography>
        </Grid>
        {btnTitle && (
          <Grid item>
            <HeaderButton
              type="primary"
              showIcon={true}
              onClick={onClickPrimary}
            >
              {btnTitle}
            </HeaderButton>
          </Grid>
        )}
        <Grid item>
          <HeaderButton type="secondary" onClick={onClickSecondary}>
            Excel Upload
          </HeaderButton>
        </Grid>
        <Grid item xs />
        {placeholder && (
          <Grid item>
            <SearchTextField
              placeholder={placeholder}
              searchInput={searchInput}
              onSearchChange={onSearchChange}
            />
          </Grid>
        )}
        {props.children && <Grid item>{props.children}</Grid>}
      </Grid>
    </Paper>
  );
}

UsersHeader.propTypes = {
  title: PropTypes.string,
  btnTitle: PropTypes.string,
  placeholder: PropTypes.string,
  onClickPrimary: PropTypes.func,
  searchInput: PropTypes.string,
  onSearchChange: PropTypes.func,
  onClickSecondary: PropTypes.func,
};
