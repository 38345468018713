import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Divider,
  Typography,
  Grid,
  Stack,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { ArticleBuilder } from "../../pages";
import { HeaderButton, MenuButton } from "../../components";
import { useState, useEffect } from "react";
import { api, urls } from "../../services";
import { useToast, useAuth } from "../../contexts";
import PropTypes from "prop-types";
import { languages, newArticle } from "../../utilities";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { useModal } from "../../hooks";
import { SendNotification } from "../popups/SendNotification";

const useStyles = makeStyles((theme) => ({
  appBar: {
    "&.MuiAppBar-root": {
      position: "relative",
      background: "white",
      color: "black",
      padding: theme.spacing(1),
    },
  },
  container: {
    height: "100%",
    background: theme.palette.common.greyBg,
    overflow: "auto",
  },
  divider: {
    "&.MuiDivider-root": {
      height: 50,
      width: 1,
      background: theme.palette.common.border,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  titleText: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  bookText: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.theme.main,
    },
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    background: theme.palette.common.border,
  },
}));

const menuOptions = [
  // { id: 1, label: "Edit" },
  { id: 2, label: "Delete" },
];

export function CreateArticle(props) {
  const classes = useStyles();
  const {
    open,
    onClose,
    artID,
    magazine,
    onSubmit,
    isPublished,
    isFreeArticle,
  } = props;
  const { showToast } = useToast();
  const { logout } = useAuth();
  const [isOpenNotifyModal, openNotifyModal, closeNotifyModal] = useModal();

  // const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [newData, setNewData] = useState(
    JSON.parse(JSON.stringify(newArticle))
  );
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openEditConfirm, setOpenEditConfirm] = useState(false);
  const [artDetails, setArtDetails] = useState(null);

  const handleCloseClick = () => {
    onClose();
  };

  const handleOnDataChange = (data) => {
    setDisableButton(false);
    setNewData(data);
  };

  const handleReadyChange = (e, v) => {
    setNewData((prev) => {
      let curr = { ...prev };
      curr.ready_to_publish = v;
      return curr;
    });
  };

  const saveDataApi = () => {
    if (!newData.feature_image.trim()) {
      setNewData((prev) => {
        let curr = { ...prev };
        curr.errors.feature_image = true;
        return curr;
      });
    }
    if (!newData.title.trim()) {
      setNewData((prev) => {
        let curr = { ...prev };
        curr.errors.title = true;
        return curr;
      });
    }
    if (!newData.author_id.trim()) {
      setNewData((prev) => {
        let curr = { ...prev };
        curr.errors.author = true;
        return curr;
      });
    }
    if (newData.article_categories.length === 0) {
      setNewData((prev) => {
        let curr = { ...prev };
        curr.errors.categories = true;
        return curr;
      });
    }
    if (newData.keywords.length === 0) {
      setNewData((prev) => {
        let curr = { ...prev };
        curr.errors.keywords = true;
        return curr;
      });
    }
    if (
      newData.components[2].author.read_time?.length === 0 ||
      newData.components[2].author.read_time === null
    ) {
      setNewData((prev) => {
        let curr = { ...prev };
        curr.errors.read_time = true;
        return curr;
      });
    }
    setIsApiCalling(true);
    setTimeout(() => {
      let keys = Object.keys({ ...newData.errors }).filter(
        (x) => newData.errors[x] === true
      );
      console.log(keys);
      if (keys.length === 0) {
        // call api
        // return;
        if (artID) {
          // edit article
          let params = { ...newData };

          api
            .post(urls.editArticle + artID, params)
            .then((res) => {
              setIsApiCalling(false);
              showToast(res.data.message, "success");
              onSubmit();
            })
            .catch((err) => {
              setIsApiCalling(false);
              if (err.response?.status === 401) {
                logout();
              } else {
                showToast(err.response?.data?.message, "error");
              }
            });
        } else {
          // create article
          let params = { ...newData };
          let url = "";
          if (!isFreeArticle) {
            url = urls.createArticle + magazine.id;
          } else {
            url = urls.createFreeArticle;
          }
          api
            .post(url, params)
            .then((res) => {
              setIsApiCalling(false);
              showToast(res.data.message, "success");
              onSubmit();
            })
            .catch((err) => {
              setIsApiCalling(false);
              if (err.response?.status === 401) {
                logout();
              } else {
                showToast(err.response?.data?.message, "error");
              }
            });
        }
      } else {
        showToast(
          "One or more input fields have incorrect/empty value",
          "error"
        );
        setIsApiCalling(false);
        setDisableButton(true);
      }
    }, 200);
  };

  const handleSaveClick = () => {
    console.log("🚀 ~ handleSaveClick ~ newData:", newData);
    if (newData.ready_to_publish) {
      //already published, show confirmation
      setOpenEditConfirm(true);
    } else {
      //call api
      saveDataApi();
    }
  };

  const onConfirmEdit = () => {
    setOpenEditConfirm(false);
    //call api
    saveDataApi();
  };

  const onConfirmDeletion = () => {
    setOpenDeleteConfirm(false);
    setIsApiCalling(true);
    api
      .delete(urls.deleteArticle + artID)
      .then((res) => {
        handleCloseClick();
        setIsApiCalling(false);
        showToast("Article deleted successfully", "success");
        onSubmit();
      })
      .catch((err) => {
        setIsApiCalling(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  // const getLanguages = () => {
  //   api
  //     .get(urls.languages)
  //     .then((res) => setLanguages(res.data.data))
  //     .catch((err) => {
  //       if (err.response?.status === 401) {
  //         logout();
  //       } else {
  //         showToast(err.response?.data?.message, "error");
  //       }
  //     });
  // };

  const getCategories = () => {
    api
      .get(urls.categoriesDropdown)
      .then((res) => setCategories(res.data.data))
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getAuthors = () => {
    let params = { page: 1, limit: 500, filter_key: "", search_key: "" };
    api
      .get(urls.authors, { params: params })
      .then((res) => setAuthors(res.data.data.authors))
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getArticleDetails = () => {
    api
      .get(urls.articleDetails + artID)
      .then((res) => {
        let data = res.data.data;
        setArtDetails(data);
        let categories = data.article_categories.map((x) => x.id);
        data.article_categories = categories;
        setNewData((prev) => ({ ...prev, ...data }));
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
          handleCloseClick();
        }
      });
  };

  useEffect(() => {
    // getLanguages();
    getCategories();
    getAuthors();
    if (isPublished) {
      setNewData((prev) => ({ ...prev, ready_to_publish: true }));
    }
  }, []);

  useEffect(() => {
    if (artID) {
      getArticleDetails();
    }
  }, [artID]);

  useEffect(() => {
    console.log(newData);
  }, [newData]);

  useEffect(() => {
    if (isFreeArticle) {
      setNewData((prev) => ({
        ...prev,
        ready_to_publish: true,
        is_free: true,
      }));
    }
  }, []);

  const handleMenuOptionClick = (m) => {
    setOpenDeleteConfirm(true);
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <AppBar className={classes.appBar} elevation={2}>
        <Toolbar>
          <Grid container alignItems="center" spacing={2}>
            <Grid item sx={{ mr: 1 }}>
              <IconButton onClick={handleCloseClick}>
                <Close />
              </IconButton>
            </Grid>
            <Grid item>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={1} alignItems="center">
                {!isFreeArticle ? (
                  <>
                    <Typography className={classes.titleText}>
                      {magazine.issue_name}
                    </Typography>
                    <div className={classes.dot} />
                    <Typography className={classes.bookText}>
                      {`പുസ്തകം ${magazine.book_number} - ${magazine.year_number}`}
                    </Typography>
                  </>
                ) : (
                  <Typography className={classes.titleText}>
                    Free Article
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs />
            <Grid item>
              <FormControlLabel
                control={<Switch color="switch" />}
                label="Ready to Publish"
                checked={newData.ready_to_publish}
                onChange={handleReadyChange}
                disabled={isPublished || isFreeArticle}
              />
            </Grid>
            <Grid item>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item>
              <MenuButton
                options={menuOptions}
                onOptionClick={handleMenuOptionClick}
                disabled={!Boolean(artID)}
              />
            </Grid>
            {isPublished && artID && artDetails && (
              <Grid item>
                <HeaderButton
                  type="secondary"
                  sx={{ padding: "20px" }}
                  onClick={openNotifyModal}
                >
                  Notify Users
                </HeaderButton>
              </Grid>
            )}
            <Grid item sx={{ width: 150 }}>
              <HeaderButton
                type="primary"
                onClick={handleSaveClick}
                disabled={isApiCalling || disableButton}
              >
                SAVE
              </HeaderButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box className={classes.container}>
        <ArticleBuilder
          // magazine={magazine}
          languages={languages}
          categories={categories}
          authors={authors}
          onDataChange={handleOnDataChange}
          data={newData}
          coverImageUploading={setIsApiCalling}
          isFreeArticle={isFreeArticle}
        />
      </Box>
      {openDeleteConfirm && (
        <ConfirmationDialog
          open={openDeleteConfirm}
          onClose={() => setOpenDeleteConfirm(false)}
          message="Are you sure you want to delete this Article?"
          onSubmit={onConfirmDeletion}
        />
      )}
      {openEditConfirm && (
        <ConfirmationDialog
          open={openEditConfirm}
          onClose={() => setOpenEditConfirm(false)}
          message="Are you sure you want to save & publish the changes?"
          onSubmit={onConfirmEdit}
        />
      )}
      {isOpenNotifyModal && (
        <SendNotification
          open={isOpenNotifyModal}
          onClose={closeNotifyModal}
          onSubmit={() => {}}
          selArticle={{ id: artID, title: newData.title }}
        />
      )}
    </Dialog>
  );
}

CreateArticle.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  artID: PropTypes.string,
  magazine: PropTypes.object,
  onSubmit: PropTypes.func,
  isPublished: PropTypes.bool,
  isFreeArticle: PropTypes.bool,
};
