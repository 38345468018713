import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Grid,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";
import {
  ListingHeader,
  ContainerCard,
  MagazineTableItem,
  TableMenuButton,
} from "../../components";
import { AddQuote } from "../popups/AddQuote";
import { makeStyles } from "@mui/styles";
import { AddAdvertisement } from "../popups/AddAdvertisement";
import { AdvertisementTableItem } from "../../components/items/AdvertisementTableItem";
import { api, urls } from "../../services";
import { useAuth, useToast } from "../../contexts";
import moment from "moment";
import { debounce } from "lodash";
import { magazine1 } from "../../assets";
import { CreateQuiz } from "../popups/CreateQuiz";
import NoDataCard from "../../components/cards/NoDataCard";
import LoaderCard from "../../components/cards/LoaderCard";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { SelectMagazine } from "../popups/SelectMagazine";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  statusItem: {
    "&.MuiTableCell-root": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      minHeight: 60,
    },
  },
  header: {
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
  // headItem: {
  //     fontWeight : 700
  //  }
}));
const menuOptions1 = [
  { id: 1, label: "Edit" },
  { id: 2, label: "Delete" },
];
const menuOptions2 = [{ id: 1, label: "Edit" }];

export function Quizzes() {
  const classes = useStyles();
  const [openAddQuiz, setOpenAddQuiz] = useState(false);
  const [quizzes, setQuizzes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [openSelectMag, setOpenSelectMag] = useState(false);
  const [magazines, setMagazines] = useState([]);
  const [selMag, setSelMag] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPage, setTotalPages] = useState(0);

  const [searchInput, setSearchInput] = useState("");

  const { showToast } = useToast();
  const { logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [selQuiz, setSelQuiz] = useState(null);
  const [confirmDelQuiz, setConfirmDelQuiz] = useState(false);
  const navigate = useNavigate();

  const getQuizzes = () => {
    api
      .get(urls.quizzes, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          search_key: searchInput,
        },
      })
      .then((res) => {
        setQuizzes(res.data.data.quiz_list);
        setTotalPages(res.data.data.pagination.total);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getLanguages = () => {
    api
      .get(urls.languages)
      .then((res) => setLanguages(res.data.data))
      .catch((err) => {
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getMagazines = () => {
    setIsLoading(true);
    api
      .get(urls.magazines, {
        params: {
          page: 1,
          limit: 100,
          filter_key: "",
          search_key: "",
        },
      })
      .then((res) => {
        setMagazines(res.data.data.magazines);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getLanguages();
    getMagazines();
  }, []);

  useEffect(() => {
    getQuizzes();
  }, [page, rowsPerPage, searchInput]);

  const delayedSearch = useCallback(
    debounce((text) => {
      setSearchInput(text);
    }, 500),
    []
  );

  const handleOnSearch = (event) => {
    delayedSearch(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleCloseAddQuiz = () => {
    setOpenAddQuiz(false);
    setSelQuiz(null);
    setSelMag(null);
  };
  const handleOnClickAddQuiz = () => {
    setOpenSelectMag(true);
  };

  const handleSelectMag = (mag) => {
    setOpenSelectMag(false);
    setSelMag(mag);
    setOpenAddQuiz(true);
  };

  const onCloseDeletion = () => {
    setSelQuiz(null);
    setConfirmDelQuiz(false);
  };

  const onConfirmDeletion = () => {
    setConfirmDelQuiz(false);
    api
      .delete(urls.deleteQuiz + selQuiz.id)
      .then((res) => {
        showToast("Quote deleted successfully", "success");
        getQuizzes();
        setSelQuiz(null);
      })
      .catch((err) => {
        setSelQuiz(null);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const handleRowClick = (quiz) => {
    if (quiz.attended_count > 0) {
      navigate(`/quizzes/${quiz.id}`);
    }
  };

  return (
    <Fragment>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <ListingHeader
            title={totalPage > 0 ? `Quizzes (${totalPage})` : "Quizzes"}
            btnTitle="Add New Quiz"
            placeholder="Search Quizzes"
            onClickPrimary={handleOnClickAddQuiz}
            onSearchChange={handleOnSearch}
          />
        </Grid>
        {isLoading && (
          <Grid item>
            <LoaderCard />
          </Grid>
        )}
        {!isLoading && (
          <Grid item>
            <ContainerCard>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.header}>Magazine</TableCell>
                      <TableCell className={classes.header}>Quiz No.</TableCell>
                      <TableCell className={classes.header}>Attended</TableCell>
                      <TableCell className={classes.header}>Status</TableCell>
                      <TableCell className={classes.header}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quizzes.map((quiz, i) => (
                      <TableRow
                        key={i}
                        sx={{ cursor: "pointer" }}
                        hover
                        // onClick={() => handleRowClick(quiz)}
                      >
                        <TableCell onClick={() => handleRowClick(quiz)}>
                          <MagazineTableItem
                            cover={quiz.magazine.image}
                            name={quiz.magazine.issue_name}
                            book={`${quiz.magazine.book_number} - ${quiz.magazine.year_number}`}
                          />
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(quiz)}>
                          {quiz.quiz_name}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(quiz)}>
                          {quiz.attended_count}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(quiz)}>
                          {quiz.result_published === true
                            ? "Winner Published"
                            : "Published"}
                        </TableCell>
                        <TableCell>
                          <TableMenuButton
                            options={
                              quiz.attended_count > 0
                                ? menuOptions2
                                : menuOptions1
                            }
                            onOptionClick={(menu) => {
                              setSelQuiz(quiz);
                              if (menu.id === 1) {
                                setOpenAddQuiz(true);
                                setSelMag(quiz.magazine);
                              } else {
                                setConfirmDelQuiz(true);
                              }
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {totalPage === 0 && <NoDataCard title="No Data Found" />}
              <TablePagination
                component="div"
                count={totalPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </ContainerCard>
          </Grid>
        )}
      </Grid>
      {openAddQuiz && (
        <CreateQuiz
          open={openAddQuiz}
          onClose={handleCloseAddQuiz}
          selMag={selMag}
          onSubmit={() => {
            handleCloseAddQuiz();
            getQuizzes();
          }}
          isEdit={Boolean(selQuiz)}
          selQuiz={selQuiz}
        />
      )}
      {openSelectMag && (
        <SelectMagazine
          open={openSelectMag}
          onClose={() => setOpenSelectMag(false)}
          onSelect={handleSelectMag}
          magazines={magazines}
        />
      )}
      {confirmDelQuiz && (
        <ConfirmationDialog
          open={confirmDelQuiz}
          onClose={onCloseDeletion}
          message="Are you sure you want to delete this Quiz?"
          onSubmit={onConfirmDeletion}
        />
      )}
    </Fragment>
  );
}
