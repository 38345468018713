import {
  Paper,
  Divider,
  Box,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  RadioButtonUnchecked,
  CheckCircle,
  ArrowDropUp,
  ArrowDropDown,
  ContentCopy,
  MoreVert,
  DeleteOutline,
} from "@mui/icons-material";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    // paddingLeft: theme.spacing(3),
    "&.MuiPaper-root": {
      // borderRadius: 0,
    },
  },
  titleBar: {
    height: 40,
  },
  titleText: {
    "&.MuiTypography-root": { fontSize: 20, fontWeight: 500 },
  },
  divider: {
    "&.MuiDivider-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  checkBox: {
    "&.MuiCheckbox-root": {
      color: "black",
      "&.Mui-checked": {
        color: theme.palette.common.greenTxt,
      },
      "&.Mui-disabled": {
        color: theme.palette.common.border,
      },
    },
  },
  vertDivider: {
    "&.MuiDivider-root": {
      width: 1,
      background: theme.palette.common.border,
      height: 30,
    },
  },
  buttons: {
    "&.MuiIconButton-root": {
      border: 1,
      borderStyle: "solid",
      borderColor: theme.palette.common.border,
      borderRadius: 4,
      height: 35,
      width: 35,
      color: "black",
    },
  },
}));

export function ComponentCard(props) {
  const classes = useStyles();
  const {
    children,
    title,
    noDivider,
    isFree,
    isFreeReadOnly,
    onIsFreeChange,
    disableAll,
    disableUp,
    disableDown,
    onMoveUp,
    onMoveDown,
    onDuplicate,
    onDelete,
    hideIsFree,
    disableDelete,
  } = props;

  return (
    <Paper className={classes.root}>
      <Box className={classes.titleBar}>
        <Stack
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography className={classes.titleText}>{title}</Typography>
          <Stack direction="row" alignItems="center" spacing={3}>
            {!hideIsFree && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkBox}
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<CheckCircle />}
                      checked={isFree}
                      onChange={(e) => onIsFreeChange(e.target.checked)}
                    />
                  }
                  label="Free"
                  disabled={disableAll || isFreeReadOnly}
                />
                <Divider className={classes.vertDivider} />
              </>
            )}
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton
                size="small"
                className={classes.buttons}
                disabled={disableAll || disableUp}
                onClick={onMoveUp}
              >
                <ArrowDropUp sx={{ height: 30, width: 30 }} />
              </IconButton>
              <IconButton
                size="small"
                className={classes.buttons}
                disabled={disableAll || disableDown}
                onClick={onMoveDown}
              >
                <ArrowDropDown sx={{ height: 30, width: 30 }} />
              </IconButton>
              <IconButton
                size="small"
                className={classes.buttons}
                disabled={disableAll}
                onClick={onDuplicate}
              >
                <ContentCopy sx={{ height: 18, width: 18 }} />
              </IconButton>
              <IconButton
                size="small"
                className={classes.buttons}
                disabled={disableAll || disableDelete}
                onClick={onDelete}
              >
                <DeleteOutline sx={{ height: 18, width: 18 }} />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      {noDivider === true ? null : <Divider className={classes.divider} />}
      {children}
    </Paper>
  );
}

ComponentCard.propTypes = {
  title: PropTypes.string,
  noDivider: PropTypes.bool,
  isFree: PropTypes.bool,
  isFreeReadOnly: PropTypes.bool,
  onIsFreeChange: PropTypes.func,
  disableAll: PropTypes.bool,
  disableUp: PropTypes.bool,
  disableDown: PropTypes.bool,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  onDuplicate: PropTypes.func,
  onDelete: PropTypes.func,
  hideIsFree: PropTypes.bool,
  disableDelete: PropTypes.bool,
};
